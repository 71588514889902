<template>
    <b-row>
        <b-col class="mb-5">
            <b-form ref="createEditForm" @submit="onSubmit" @reset="onReset" :novalidate="true" :validated="true">
                <b-form-group
                        :label="$t('solution.fullName')"
                        label-for="full-name"
                        :description="$t('solution.fullName.description')"
                >
                    <b-form-input id="full-name" v-model="fullName" type="text" required trim></b-form-input>
                </b-form-group>
                <b-form-group
                        :label="$t('solution.shortName')"
                        label-for="short-name"
                        :description="$t('solution.shortName').description"
                >
                    <b-form-input id="short-name" v-model="shortName" type="text" required trim></b-form-input>
                </b-form-group>
                <b-form-group :label="$t('solution.solutionType')" label-for="solution-type">
                    <b-form-select id="solution-type" v-model="solutionType" :options="solutionTypes" required>
                        <template #first>
                            <b-form-select-option value="" disabled>-- {{ $t('forms.selectType') }} --</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
                <b-form-group v-if="solutionType == 'IDEA'" :label="$t('solution.ideaAuthor')" label-for="author">
                    <b-form-input id="author" v-model="author" type="text" trim></b-form-input>
                </b-form-group>
                <hr>
                <b-form-row v-if="solutionType != ''"><h3>{{ $t('realization') }}</h3></b-form-row>
                <div v-if="solutionType == 'BUSINESS_SOLUTION'">
                    <b-form-group
                            :label="$t('realization.practiceAffiliation')"
                            label-for="practice-affiliation"
                            :description="$t('realization.practiceAffiliation.description')"
                    >
                        <b-form-select id="practice-affiliation" v-model="practiceAffiliationSingle" :options="bpSolutions">
                            <template #first>
                                <b-form-select-option value="">{{ $t('forms.no') }}</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                    <b-form-group
                            :label="$t('realization.producer')"
                            label-for="producer"
                            :description="$t('realization.producer.description')"
                    >
                        <b-form-input id="producer" v-model="producer" type="text" trim></b-form-input>
                    </b-form-group>
                    <b-form-group
                            :label="$t('realization.cost')"
                            label-for="cost"
                            :description="$t('realization.cost.description')"
                    >
                        <b-form-input id="cost" v-model="cost" type="number"></b-form-input>
                    </b-form-group>
                </div>
                <div v-if="solutionType == 'BEST_PRACTICE'">
                    <b-form-group
                            :label="$t('realization.sourceLink')"
                            label-for="source-link"
                            :description="$t('realization.sourceLink.description')"
                    >
                        <b-form-input id="source-link" v-model="sourceLink" type="text" trim></b-form-input>
                    </b-form-group>
                    <b-form-group
                            :label="$t('solution.implementations')"
                            label-for="implementations"
                            :description="$t('solution.implementations.description')"
                    >
                        <b-form-input id="implementations" v-model="implementations" type="text" trim></b-form-input>
                    </b-form-group>
                </div>
                <div v-if="solutionType == 'PROJECT'">
                    <b-form-group
                            :label="$t('realization.practiceAffiliation')"
                            label-for="practice-affiliation"
                            :description="$t('realization.practiceAffiliation.description')"
                    >
                        <b-form-select id="practice-affiliation" v-model="practiceAffiliationSingle" :options="bpSolutions">
                            <template #first>
                                <b-form-select-option value="">{{ $t('forms.no') }}</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                    <b-form-group
                            :label="$t('realization.sourceLink.PROJECT')"
                            label-for="source-link"
                            :description="$t('realization.sourceLink.PROJECT.description')"
                    >
                        <b-form-input id="source-link" v-model="sourceLink" type="text" trim></b-form-input>
                    </b-form-group>
                </div>
                <div v-if="solutionType == 'IDEA'">
                    <b-form-group
                            :label="$t('realization.practiceAffiliation')"
                            label-for="practice-affiliation"
                            :description="$t('realization.practiceAffiliation.description')"
                    >
                        <b-form-select id="practice-affiliation" v-model="practiceAffiliationSingle" :options="bpSolutions">
                            <template #first>
                                <b-form-select-option value="">{{ $t('forms.no') }}</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                    <b-form-group
                            :label="$t('realization.sourceLink.IDEA')"
                            label-for="source-link"
                            :description="$t('realization.sourceLink.IDEA.description')"
                    >
                        <b-form-input id="source-link" v-model="sourceLink" type="text" trim></b-form-input>
                    </b-form-group>
                </div>
                <hr>
                <b-form-row><h3>{{ $t('solution.sphere') }}</h3></b-form-row>
                <b-form-group :label="$t('solution.sphereType.description')" label-for="sphere-type">
                    <b-form-select id="sphere-type" v-model="sphere" :options="sphereTypes" required>
                        <template #first>
                            <b-form-select-option value="" disabled>-- {{ $t('forms.selectType') }} --</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
                <div v-if="sphere == 'CITY_MANAGEMENT'">
                    <b-form-group
                            :label="$t('solution.cityManagementPurview')"
                            label-for="city-management-purview">
                        <b-form-select id="city-management-purview" v-model="cityManagementPurview" :options="cityManagementPurviewTypes" required>
                            <template #first>
                                <b-form-select-option value="" disabled>-- {{ $t('forms.selectType') }} --</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                    <b-form-group
                            :label="$t('solution.industry')"
                            label-for="industry"
                            :description="$t('solution.industry.description')"
                    >
                        <VueTypeaheadBootstrap
                                id="industry"
                                :data="autocompleteIndustryValues"
                                v-model="industry"
                                :showOnFocus="true"
                                :showAllResults="true"
                        ></VueTypeaheadBootstrap>
                    </b-form-group>
                </div>
                <div v-if="sphere == 'SERVICES_PROVISION'">
                    <b-form-group
                            :label="$t('solution.serviceTypes')"
                            :description="$t('solution.serviceTypes.description')"
                    >
                        <Multiselect
                                v-model="functionServiceTypes"
                                :options="functionServiceTypesOptions"
                                :group-select="true"
                                group-values="serviceTypes"
                                group-label="groupName"
                                :multiple="true"
                                :hideSelected="true"
                                placeholder=""
                                :showLabels="false"
                                trackBy="name"
                                label="name"
                        ></Multiselect>
                    </b-form-group>
                </div>
                <div v-if="sphere == 'TERRITORIAL_GROWTH'">
                    <b-form-group
                            :label="$t('solution.projectType')"
                            label-for="project-type">
                        <b-form-select id="project-type" v-model="projectType" :options="territorialGrowthProjectTypes">
                        </b-form-select>
                    </b-form-group>
                </div>
                <hr>
                <b-form-row><h3>{{ $t('solution.image') }}</h3></b-form-row>
                <b-form-row>
                    <b-img v-if="avatarImage != ''" :src="getImageUrl(avatarImage)"></b-img><br>
                    <file-upload
                            extensions="gif,jpg,jpeg,png,webp"
                            accept="image/png,image/gif,image/jpeg,image/webp"
                            name="avatarImage"
                            v-bind:customAction="addAvatarImage"
                            :active="true"
                            @input-file="avatarImageAdded"
                            ref="uploadAvatarImage"
                    >
                        <p class="text-success ml-1">
                            {{ avatarImage == '' ? $t('forms.add') : $t('forms.edit') }}
                        </p>
                    </file-upload>
                </b-form-row>
                <hr>
                <b-form-row><h3>{{ $t('solution.description') }}</h3></b-form-row>
                <label for="new-target">{{ $t('solution.description.targets') }}</label>
                <b-input-group class="mb-1">
                    <b-form-input id="new-target" v-model="newTarget" type="text" trim></b-form-input>
                    <b-input-group-append>
                        <b-button type="button" variant="outline-secondary" :disabled="newTarget == ''" v-on:click="addNewTarget()">
                            {{ $t('forms.add') }}
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
                <b-table-simple small hover responsive>
                    <b-tbody>
                        <b-tr v-for="(target, index) in targets" v-bind:key="index">
                            <b-td>{{ target }}</b-td>
                            <b-td>
                                <b-button type="button" size="sm" variant="outline-secondary" v-on:click="removeTarget(index)">
                                    {{ $t('forms.delete') }}
                                </b-button>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
                <b-form-group
                        :label="$t('solution.description.text')"
                        label-for="solution-description-text"
                        :description="$t('solution.description.text.description')"
                >
                    <v-md-editor
                            v-model="solutionDescriptionText"
                            height="400px"
                            left-toolbar="undo redo clear | h bold italic strikethrough quote | ul ol table hr | link image code"
                    ></v-md-editor>
                </b-form-group>
                <b-form-group
                        :label="$t('solution.description.locationDescription')"
                        label-for="location-description"
                        :description="locationQueryAttribution"
                >
                    <VueTypeaheadBootstrap
                            id="location-description"
                            :data="autocompleteLocationDescriptionValues"
                            v-model="locationDescription"
                            :showOnFocus="true"
                            :showAllResults="true"
                            style="display: block"
                    ></VueTypeaheadBootstrap>
                    <b-button
                            type="button"
                            variant="outline-secondary"
                            class="mt-1"
                            :disabled="locationDescription == null || locationDescription == ''"
                            v-on:click="findLocationByAddress()"
                    >
                        <i class="fas fa-map-marker-alt"></i> {{ $t('forms.map') }}
                    </b-button>
                </b-form-group>
                <label for="new-location-lat">{{ $t('solution.description.location') }}</label>
                <b-form-row>
                    <b-col>
                        <p class="font-italic">{{ $t('solution.description.location.help') }}</p>
                        <b-input-group class="mb-1" prepend="Lat">
                            <b-form-input id="new-location-lat" v-model="newLocationLat" ref="newLocationLat" type="text" v-on:update="latLonChange" trim></b-form-input>
                        </b-input-group>
                        <b-input-group class="mb-1" prepend="Lon">
                            <b-form-input id="new-location-lon" v-model="newLocationLon" ref="newLocationLon" type="text" v-on:update="latLonChange" trim></b-form-input>
                        </b-input-group>
                        <b-button type="button" class="mb-1" variant="outline-secondary" :disabled="newLocationLat == '' || newLocationLon == ''" v-on:click="addNewLocation()">
                            {{ $t('forms.save') }}
                        </b-button>
                    </b-col>
                    <b-col>
                        <LeafletMap style="height:300px" ref="locationsMap" :markers="locations" v-on:listenLatLon="listenLatLon" clickable="true"></LeafletMap>
                    </b-col>
                </b-form-row>
                <b-table-simple small hover responsive>
                    <b-tbody>
                        <b-tr v-for="(location, index) in locations" v-bind:key="index">
                            <b-td>{{ formatLocation(location.lat, location.lon) }}</b-td>
                            <b-td><b-button type="button" size="sm" variant="outline-secondary" v-on:click="removeLocation(index)">{{ $t('forms.delete') }}</b-button></b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
                <b-form-group
                        :label="$t('solution.description.achievements')"
                        label-for="solution-description-achievements"
                        :description="$t('solution.description.achievements.description')"
                >
                    <b-form-textarea
                            id="solution-description-achievements"
                            v-model="solutionDescriptionAchievements"
                            rows="3"
                            max-rows="6"
                            trim
                    ></b-form-textarea>
                </b-form-group>
                <label for="new-image">{{ $t('solution.description.images') }}</label>
                <b-form-row>
                    <file-upload
                            extensions="gif,jpg,jpeg,png,webp"
                            accept="image/png,image/gif,image/jpeg,image/webp"
                            name="image"
                            v-bind:customAction="addNewImage"
                            :active="true"
                            @input-file="imageAdded"
                            ref="uploadImage"
                    >
                        <p class="text-success ml-1">
                            {{ $t('forms.add') }}
                        </p>
                    </file-upload>
                </b-form-row>
                <b-table-simple small hover responsive>
                    <b-tbody>
                        <b-tr v-for="(im, index) in images" v-bind:key="index">
                            <b-td><b-img :src="getImageUrl(im)" fluid></b-img></b-td>
                            <b-td>{{ getImageUrl(im) }}</b-td>
                            <b-td>
                                <b-button type="button" size="sm" variant="outline-secondary" v-on:click="removeImage(index)">
                                    {{ $t('forms.delete') }}
                                </b-button>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
                <b-form-group :label="$t('solution.description.scale')" label-for="scale">
                    <b-form-select id="scale" v-model="scale" :options="scaleTypes" required>
                        <template #first>
                            <b-form-select-option value="" disabled>-- {{ $t('forms.selectType') }} --</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
                <label>{{ $t('solution.description.geometry') }}</label>
                <p class="small">{{ $t('solution.description.geometry.description') }}</p>
                <b-form-row>
                    <b-col>
                        <p class="font-italic">{{ $t('solution.description.geometry.help') }}</p>
                        <b-card-group deck>
                            <b-card
                                    class="mb-1"
                                    style="min-width: 60%;"
                                    v-for="(polygon, index) in this.polygons"
                                    v-bind:key="index"
                                    :border-variant="index == polygonSelected ? 'info' : 'default'"
                            >
                                <b-form-radio v-model="polygonSelected" name="polygon-radios" :value="index" v-on:change="polygonSelect">
                                    {{ index == polygonSelected ? $t('forms.selected') : $t('forms.select') }}
                                </b-form-radio>
                                <b-form-group :label="$t('forms.name')">
                                    <b-form-input size="sm" v-model="polygon.name" type="text" trim></b-form-input>
                                </b-form-group>
                                <b-form-group :label="$t('forms.color')">
                                    <b-form-input size="sm" v-model="polygon.color" type="text" required trim></b-form-input>
                                    <InputColorPicker class="mt-1" v-model="polygon.color"></InputColorPicker>
                                </b-form-group>
                                <b-table-simple small responsive style="height: 5rem;" class="overflow-auto">
                                    <b-tbody>
                                        <b-tr v-for="(point, pIndex) in polygon.points" v-bind:key="pIndex">
                                            <b-td>
                                                <b-form-input size="sm" v-model="point.lat" type="text"></b-form-input>
                                            </b-td>
                                            <b-td>
                                                <b-form-input size="sm" v-model="point.lon" type="text"></b-form-input>
                                            </b-td>
                                            <b-td>
                                                <b-button type="button" size="sm" variant="outline-secondary" v-on:click="removeGeoPoint(index, pIndex)">
                                                    {{ $t('forms.delete') }}
                                                </b-button>
                                            </b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                                <b-button
                                        type="button"
                                        size="sm"
                                        variant="outline-secondary"
                                        v-on:click="polygons.splice(index, 1); polygonSelected = 0; polygonSelect()"
                                >
                                    {{ $t('polygon.delete') }}
                                </b-button>
                            </b-card>
                        </b-card-group>
                        <b-button type="button" size="sm" variant="outline-secondary" v-on:click="addPolygon()">
                            {{ $t('forms.add') }}
                        </b-button>
                    </b-col>
                    <b-col>
                        <LeafletMap style="height:318px" ref="geometryMap" :polygons="this.polygons" v-on:listenLatLon="listenGeoLatLon" clickable="true"></LeafletMap>
                    </b-col>
                </b-form-row>
                <label>{{ $t('solution.description.files') }}</label>
                <b-form-row>
                    <file-upload :multiple=true @input-file="addFile">
                        <p class="text-success ml-1">
                            {{ $t('forms.add') }}
                        </p>
                    </file-upload>
                </b-form-row>
                <b-table-simple small hover responsive v-if="this.files.length > 0">
                    <b-thead>
                        <b-tr>
                            <b-th>ID</b-th>
                            <b-th>{{ $t('forms.name') }}</b-th>
                            <b-th></b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-for="(file, index) in files" v-bind:key="index">
                            <b-td>{{ file.substring(0, file.indexOf('/')) }}</b-td>
                            <b-td>{{ file.substring(file.indexOf('/') + 1) }}</b-td>
                            <b-td>
                                <b-button type="button" size="sm" variant="outline-secondary" v-on:click="removeFile(index)">
                                    {{ $t('forms.delete') }}
                                </b-button>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
                <hr>
                <b-form-row><h3>{{ $t('solution.focusTarget') }}</h3></b-form-row>
                <b-card-group deck>
                    <b-card
                            class="mb-1"
                            no-body
                            border-variant="info"
                            style="min-width: 20rem; max-width: 20rem;"
                            v-for="(target, index) in focusTargets"
                            v-bind:key="index"
                    >
                        <b-card-body class="p-2">
                            <b-card-text>
                                <b-form-group :label="$t('solution.segment')">
                                    <b-form-select size="sm" v-model="target.segment" :options="segmentTypes">
                                    </b-form-select>
                                </b-form-group>
                                <b-form-group :label="$t('solution.targetGroup')">
                                    <b-form-input size="sm" v-model="target.targetGroup" type="text" required trim></b-form-input>
                                </b-form-group>
                                <b-form-group :label="$t('solution.situation')">
                                    <b-input-group size="sm">
                                        <b-form-input v-model="target.newSituation" type="text" trim></b-form-input>
                                        <b-input-group-append>
                                            <b-button
                                                    type="button"
                                                    variant="outline-secondary"
                                                    :disabled="target.newSituation == ''"
                                                    v-on:click="target.situations.push(target.newSituation); target.newSituation = ''"
                                            >
                                                +
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                    <ul>
                                        <li v-for="(situation, index) in target.situations" v-bind:key="index">
                                            {{ situation }}
                                            <b-button-group size="sm" class="ml-auto m-1">
                                                <b-button
                                                        type="button"
                                                        variant="outline-secondary"
                                                        v-on:click="target.situations.splice(index, 1)"
                                                >
                                                    <i class="fas fa-ban"></i>
                                                </b-button>
                                                <b-button
                                                        type="button"
                                                        variant="outline-secondary"
                                                        v-on:click="target.newSituation = situation; target.situations.splice(index, 1)"
                                                >
                                                    <i class="fas fa-pen"></i>
                                                </b-button>
                                            </b-button-group>
                                        </li>
                                    </ul>
                                </b-form-group>
                                <b-form-group :label="$t('solution.characteristic.target')">
                                    <b-form-select size="sm" v-model="target.characteristic" :options="characteristicTypes">
                                    </b-form-select>
                                </b-form-group>
                                <b-form-group :label="$t('solution.indicators')">
                                    <b-form-input size="sm" v-model="target.indicators" type="text" trim></b-form-input>
                                </b-form-group>
                                <b-form-group :label="$t('solution.targetValues')">
                                    <b-form-input size="sm" v-model="target.targetValues" type="text" trim></b-form-input>
                                </b-form-group>
                                <b-form-group :label="$t('solution.focusTargetRisk.comment')">
                                    <b-form-textarea v-model="target.comment" rows="2" max-rows="3"></b-form-textarea>
                                </b-form-group>
                            </b-card-text>
                            <b-button type="button" size="sm" class="float-right" variant="outline-info" v-on:click="removeFocusTarget(index)">
                                {{ $t('forms.delete') }}
                            </b-button>
                        </b-card-body>
                    </b-card>
                </b-card-group>
                <b-form-row>
                    <b-button type="button" class="ml-1" variant="outline-secondary" v-on:click="addNewFocusTarget()">
                        {{ $t('forms.add') }}
                    </b-button>
                </b-form-row>
                <hr>
                <b-form-row><h3>{{ $t('solution.risks') }}</h3></b-form-row>
                <b-card-group deck>
                    <b-card
                            class="mb-1"
                            no-body
                            border-variant="warning"
                            style="min-width: 20rem; max-width: 20rem;"
                            v-for="(risk, index) in risks"
                            v-bind:key="index"
                    >
                        <b-card-body class="p-2">
                            <b-card-text>
                                <b-form-group :label="$t('solution.segment')">
                                    <b-form-select size="sm" v-model="risk.segment" :options="segmentTypes">
                                    </b-form-select>
                                </b-form-group>
                                <b-form-group :label="$t('solution.targetGroup')">
                                    <b-form-input size="sm" v-model="risk.targetGroup" type="text" required trim></b-form-input>
                                </b-form-group>
                                <b-form-group :label="$t('solution.situation')">
                                    <b-input-group  size="sm">
                                        <b-form-input v-model="risk.newSituation" type="text" trim></b-form-input>
                                        <b-input-group-append>
                                            <b-button
                                                    type="button"
                                                    variant="outline-secondary"
                                                    :disabled="risk.newSituation == ''"
                                                    v-on:click="risk.situations.push(risk.newSituation); risk.newSituation = ''"
                                            >
                                                +
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                    <ul>
                                        <li v-for="(situation, index) in risk.situations" v-bind:key="index">
                                            {{ situation }}
                                            <b-button-group size="sm" class="ml-auto m-1">
                                                <b-button
                                                        type="button"
                                                        variant="outline-secondary"
                                                        v-on:click="risk.situations.splice(index, 1)"
                                                >
                                                    <i class="fas fa-ban"></i>
                                                </b-button>
                                                <b-button
                                                        type="button"
                                                        variant="outline-secondary"
                                                        v-on:click="risk.newSituation = situation; risk.situations.splice(index, 1)"
                                                >
                                                    <i class="fas fa-pen"></i>
                                                </b-button>
                                            </b-button-group>
                                        </li>
                                    </ul>
                                </b-form-group>
                                <b-form-group :label="$t('solution.characteristic.risk')">
                                    <b-form-select size="sm" v-model="risk.characteristic" :options="characteristicTypes">
                                    </b-form-select>
                                </b-form-group>
                                <b-form-group :label="$t('solution.indicators')">
                                    <b-form-input size="sm" v-model="risk.indicators" type="text" trim></b-form-input>
                                </b-form-group>
                                <b-form-group :label="$t('solution.targetValues')">
                                    <b-form-input size="sm" v-model="risk.targetValues" type="text" trim></b-form-input>
                                </b-form-group>
                                <b-form-group :label="$t('solution.focusTargetRisk.comment')">
                                    <b-form-textarea v-model="risk.comment" rows="2" max-rows="3"></b-form-textarea>
                                </b-form-group>
                            </b-card-text>
                            <b-button type="button" size="sm" class="float-right" variant="outline-warning" v-on:click="removeRisk(index)">
                                {{ $t('forms.delete') }}
                            </b-button>
                        </b-card-body>
                    </b-card>
                </b-card-group>
                <b-form-row>
                    <b-button type="button" class="ml-1" variant="outline-secondary" v-on:click="addNewRisk()">
                        {{ $t('forms.add') }}
                    </b-button>
                </b-form-row>
                <hr>
                <b-form-row><h3>{{ $t('solution.expertOpinion') }}</h3></b-form-row>
                <b-form-group
                        :label="$t('solution.expertOpinion.rating')"
                        label-for="rating"
                        :description="$t('solution.expertOpinion.rating.description')"
                >
                    <b-form-input id="rating" type="range" v-model="rating" min="0" max="5" step="0.1"></b-form-input>
                    <span class="mt-2">{{ rating }}</span>
                </b-form-group>
                <b-form-group
                        :label="$t('solution.expertOpinion.text')"
                        label-for="eo-text"
                        :description="$t('solution.expertOpinion.text.description')"
                >
                    <b-form-textarea id="eo-text"  v-model="expertOpinionText" rows="3" max-rows="6"></b-form-textarea>
                </b-form-group>

                <b-button type="submit" variant="primary">{{ $t('forms.save_changes') }}</b-button>
                <b-button type="reset" class="ml-1" variant="danger" v-if="this.id == null">{{ $t('forms.reset') }}</b-button>
            </b-form>
        </b-col>
    </b-row>
</template>
<script>
    import SolutionAPI from "../../mixins/SolutionAPI";
    import ImageAPI from "../../mixins/ImageAPI";
    import FileAPI from "../../mixins/FileAPI";
    import FileUpload from 'vue-upload-component';
    import LeafletMap from "../../components/LeafletMap";
    import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
    import Multiselect from 'vue-multiselect';
    import 'vue-multiselect/dist/vue-multiselect.min.css';
    import InputColorPicker from "vue-native-color-picker";
    import VMdEditor from '@kangc/v-md-editor';
    import '@kangc/v-md-editor/lib/style/base-editor.css';
    import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
    import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
    import enUS from '@kangc/v-md-editor/lib/lang/en-US';
    import DmsCoordinates from "dms-conversion";

    VMdEditor.use(vuepressTheme);
    VMdEditor.lang.use('en-US', enUS);

    export default {
        name: "CreateSolution",
        props: ["id"],
        mixins: [SolutionAPI, ImageAPI, FileAPI],
        components: {
            FileUpload,
            LeafletMap,
            VMdEditor,
            VueTypeaheadBootstrap,
            Multiselect,
            InputColorPicker
        },
        data() {
            return {
                loadID: this.id,
                solutionTypes: [
                    {value: "BUSINESS_SOLUTION", text: this.$t("solution.solutionType.BUSINESS_SOLUTION")},
                    {value: "BEST_PRACTICE", text: this.$t("solution.solutionType.BEST_PRACTICE")},
                    {value: "PROJECT", text: this.$t("solution.solutionType.PROJECT")},
                    {value: "IDEA", text: this.$t("solution.solutionType.IDEA")}
                ],
                sphereTypes: [
                    {value: "CITY_MANAGEMENT", text: this.$t("solution.sphere.CITY_MANAGEMENT")},
                    {value: "SERVICES_PROVISION", text: this.$t("solution.sphere.SERVICES_PROVISION")},
                    {value: "TERRITORIAL_GROWTH", text: this.$t("solution.sphere.TERRITORIAL_GROWTH")}
                ],
                cityManagementPurviewTypes: [
                    {value: "REGULATION", text: this.$t("solution.cityManagementPurview.REGULATION")},
                    {value: "ACTIVITY", text: this.$t("solution.cityManagementPurview.ACTIVITY")},
                    {value: "TERRITORY_MANAGEMENT", text: this.$t("solution.cityManagementPurview.TERRITORY_MANAGEMENT")},
                    {value: "LOCAL_GOVERNMENT", text: this.$t("solution.cityManagementPurview.LOCAL_GOVERNMENT")}
                ],
                functionServiceTypesOptions: [],
                territorialGrowthProjectTypes: [
                    {value: "LOCAL_TERRITORY_COMPLEX_DEVELOPMENT", text: this.$t("solution.territorialGrowth.LOCAL_TERRITORY_COMPLEX_DEVELOPMENT")},
                    {value: "URBAN_TRANSFORMATION", text: this.$t("solution.territorialGrowth.URBAN_TRANSFORMATION")},
                    {value: "IMPROVEMENT_OF_PUBLIC_SERVICES", text: this.$t("solution.territorialGrowth.IMPROVEMENT_OF_PUBLIC_SERVICES")}
                ],
                scaleTypes: [
                    {value: "ON_SITE", text: this.$t("solution.description.scale.ON_SITE")},
                    {value: "LOCAL", text: this.$t("solution.description.scale.LOCAL")},
                    {value: "DISTRICT", text: this.$t("solution.description.scale.DISTRICT")},
                    {value: "CITY", text: this.$t("solution.description.scale.CITY")},
                    {value: "REGION", text: this.$t("solution.description.scale.REGION")},
                    {value: "FEDERAL", text: this.$t("solution.description.scale.FEDERAL")},
                    {value: "WORLDWIDE", text: this.$t("solution.description.scale.WORLDWIDE")}
                ],
                segmentTypes: [
                    {value: "PEOPLE", text: this.$t("solution.segment.PEOPLE")},
                    {value: "BUSINESS", text: this.$t("solution.segment.BUSINESS")},
                    {value: "GOVERNMENT", text: this.$t("solution.segment.GOVERNMENT")},
                ],
                characteristicTypes: [
                    {value: "PROVISION", text: this.$t("solution.characteristic.PROVISION")},
                    {value: "QUALITY", text: this.$t("solution.characteristic.QUALITY")},
                    {value: "VARIETY", text: this.$t("solution.characteristic.VARIETY")},
                    {value: "AVAILABILITY", text: this.$t("solution.characteristic.AVAILABILITY")},
                ],
                bpSolutions: [],
                fullName: "",
                shortName: "",
                solutionType: "",
                author: "",
                practiceAffiliationSingle: "",
                producer: "",
                cost: "",
                sourceLink: "",
                implementations: "",
                sphere: "",
                cityManagementPurview: "",
                industry: "",
                infrastructures: [],
                infrastructureFunction: "",
                functionServiceTypes: [],
                projectType: "",
                avatarImage: "",
                newTarget: "",
                targets: [],
                solutionDescriptionText: "",
                newLocationLat: "",
                newLocationLon: "",
                locationDescription: "",
                locationQueryAttribution: "",
                locations: [],
                solutionDescriptionAchievements: "",
                newImage: "",
                images: [],
                scale: "",
                polygons: [],
                polygonSelected: 0,
                files: [],
                focusTargets: [],
                risks: [],
                rating: 0.0,
                expertOpinionText: "",
                autocompleteIndustryValues: [],
                autocompleteInfrastructureFunctionValues: [],
                autocompleteFunctionServiceTypeValues: [],
                autocompleteLocationDescriptionValues: []
            }
        },
        async mounted() {
            this.loadSolutions();
            this.loadAutocompleteValues();
            this.loadServiceTypes();
            if (this.id != null) {
                this.loadID = this.id;
                let getSolution = (await this.getSolution(this.loadID)).data;
                if (getSolution.code == 0) {
                    let s = getSolution.data;
                    this.fullName = s.fullName;
                    this.shortName = s.shortName;
                    this.solutionType = s.solutionType;
                    this.author = s.author;
                    if (s.realization != null && s.realization.practiceAffiliation.length > 0) {
                        s.realization.practiceAffiliation.forEach(async pa => {
                            let paSol = (await this.getSolution(pa)).data;
                            if (paSol.code == 0) {
                                this.practiceAffiliationSingle = paSol.data.id;
                            }
                        });
                    }
                    this.producer = s.realization.producer;
                    this.cost = s.realization.cost;
                    this.sourceLink = s.realization.sourceLink;
                    this.implementations = s.realization.implementations;
                    this.sphere = s.sphereDetails.sphereType;
                    this.cityManagementPurview = s.sphereDetails.cityManagementPurview;
                    this.industry = s.sphereDetails.industry;
                    this.infrastructures = s.sphereDetails.infrastructures;
                    this.infrastructureFunction = s.sphereDetails.infrastructureFunction;
                    this.functionServiceTypes = s.sphereDetails.functionServiceTypes;
                    this.projectType = s.sphereDetails.projectType;
                    this.avatarImage = s.image;
                    this.targets = s.description.targets;
                    this.solutionDescriptionText = s.description.text;
                    this.locationDescription = s.description.locationDescription == null ? "" : s.description.locationDescription;
                    this.locations = s.description.locations;
                    this.$refs.locationsMap.updateBounds(this.locations);
                    this.solutionDescriptionAchievements = s.description.achievements;
                    this.images = s.description.images;
                    this.scale = s.description.scale;
                    if (s.description.geometry != null) {
                        this.polygons = s.description.geometry.polygons;
                        this.$refs.geometryMap.updateBounds(this.polygons.flatMap(p => p.points));
                    }
                    this.files = s.description.files;
                    this.rating = s.expertOpinion.rating;
                    this.expertOpinionText = s.expertOpinion.text;
                    this.focusTargets = [];
                    this.risks = [];
                    let ftr = await this.fetchFTR(s.id);
                    if (ftr.data.code == 0) {
                        ftr.data.objects.forEach(ftr => {
                            ftr.newSituation = "";
                            if (ftr.isRisk) {
                                this.risks.push(ftr);
                            } else {
                                this.focusTargets.push(ftr);
                            }
                        });
                    }
                } else {
                    this.$router.push("/404");
                }
            }
        },
        watch: {
            locationDescription: function(val) {
                this.locationDescription = val.trim();
            }
        },
        methods: {
            async loadSolutions() {
                let a = await this.listByTime('BEST_PRACTICE', null, 0, 1000); //todo: method without pagination
                this.bpSolutions = a.data.objects.map(s => {
                        let item = {};
                        item.value = s.id;
                        item.text = s.shortName;
                        return item;
                    }
                );
            },
            async addAvatarImage(file) {
                let imageId = await this.saveImage(file);
                this.avatarImage = imageId;
            },
            avatarImageAdded() {
                this.$refs.uploadAvatarImage.active = true;
            },
            onSubmit(event) {
                event.preventDefault();
                var elem = null;
                for(var i = 0; i < this.$refs['createEditForm'].length; i++) {
                    if(!this.$refs['createEditForm'][i].checkValidity()) {
                        elem = this.$refs['createEditForm'][i];
                        break;
                    }
                }
                if (elem != null) {
                    let offsetTop = elem.getBoundingClientRect().top + window.scrollY;
                    var scrollDiv = offsetTop - 102; //navbar height + label height
                    window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
                    return;
                }
                let solution = {
                    id: this.loadID,
                    timestamp: 0,
                    fullName: this.fullName,
                    shortName: this.shortName,
                    solutionType: this.solutionType,
                    author: this.trimTN(this.author),
                    realization: {
                        producer: this.trimTN(this.producer),
                        cost: this.cost,
                        sourceLink: this.trimTN(this.sourceLink),
                        implementations: this.trimTN(this.implementations),
                        practiceAffiliation: []
                    },
                    sphereDetails: {
                        sphereType: this.sphere,
                        cityManagementPurview: this.trimTN(this.cityManagementPurview),
                        industry: this.trimTN(this.industry),
                        infrastructures: this.infrastructures,
                        infrastructureFunction: this.trimTN(this.infrastructureFunction),
                        functionServiceTypes: this.functionServiceTypes,
                        projectType: this.trimTN(this.projectType),
                    },
                    image: this.avatarImage,
                    description: {
                        targets: this.targets,
                        text: this.solutionDescriptionText,
                        locationDescription: this.locationDescription == null ? "" : this.locationDescription,
                        locations: this.locations,
                        achievements: this.trimTN(this.solutionDescriptionAchievements),
                        images: this.images,
                        scale: this.scale,
                        geometry: { polygons: this.polygons },
                        files: this.files
                    },
                    focusTargetsAndRisks: [],
                    expertOpinion: {
                        rating: this.rating,
                        text: this.trimTN(this.expertOpinionText),
                        comments: []
                    },
                    practiceRealizations: []
                };
                if (this.practiceAffiliationSingle != "") {
                    solution.realization.practiceAffiliation = [this.practiceAffiliationSingle];
                }
                if (this.focusTargets.length > 0) {
                    this.focusTargets.forEach(ft => {
                            solution.focusTargetsAndRisks.push({
                                isRisk: false,
                                segment: ft.segment,
                                targetGroup: ft.targetGroup,
                                situations: ft.situations,
                                characteristic: ft.characteristic,
                                indicators: ft.indicators,
                                targetValues: ft.targetValues,
                                comment: ft.comment
                            });
                    });
                }
                if (this.risks.length > 0) {
                    this.risks.forEach(risk => {
                            solution.focusTargetsAndRisks.push({
                                isRisk: true,
                                segment: risk.segment,
                                targetGroup: risk.targetGroup,
                                situations: risk.situations,
                                characteristic: risk.characteristic,
                                indicators: risk.indicators,
                                targetValues: risk.targetValues,
                                comment: risk.comment
                            });
                    });
                }
                this.saveSolution(solution).then(data => { this.loadID = data.id });
            },
            onReset(event) {
                event.preventDefault();
                this.fullName = "";
                this.shortName = "";
                this.solutionType = "";
                this.author = "";
                this.practiceAffiliationSingle = "";
                this.producer = "";
                this.cost = "";
                this.sourceLink = "";
                this.implementations = "";
                this.sphere = "";
                this.cityManagementPurview = "";
                this.industry = "";
                this.infrastructures = [];
                this.infrastructureFunction = "";
                this.functionServiceTypes = [];
                this.projectType = "";
                this.avatarImage = "";
                this.newTarget = "";
                this.targets = [];
                this.solutionDescriptionText = "";
                this.newLocationLat = "";
                this.newLocationLon = "";
                this.locationDescription = "";
                this.locationQueryAttribution = "";
                this.locations = [{lat: 59.9362145, lon: 30.3024282}];
                this.solutionDescriptionAchievements = "";
                this.newImage = "";
                this.images = [];
                this.scale = "";
                this.polygons = [];
                this.polygonSelected = 0;
                this.files = [];
                this.rating = 0.0;
                this.expertOpinionText = "";
                this.focusTargets = [];
                this.risks = [];
            },
            addNewTarget() {
                this.targets.push(this.newTarget);
                this.newTarget = '';
            },
            removeTarget(index) {
                this.targets.splice(index, 1);
            },
            async findLocationByAddress() {
                let fa = await this.findAddress(this.locationDescription);
                if (fa.data.code == 0) {
                    this.listenLatLon({ lat: fa.data.data.first.lat, lng: fa.data.data.first.lon});
                    this.locationQueryAttribution = fa.data.data.second;
                    this.$refs.locationsMap.updateBounds(this.locations);
                }
            },
            addNewLocation() {
                const v = [this.newLocationLat, this.newLocationLon];
                const [x, y] = v.map(this.parseDms);
                if (!isNaN(x) && !isNaN(y)) {
                    this.locations.push({lat: x, lon: y});
                    this.newLocationLat = '';
                    this.newLocationLon = '';
                }
                if (this.locationDescription == null || this.locationDescription == "") {
                    return this.findLocationDescription(x, y)
                    .then((response) => {
                        if (response.data.code == 0) {
                            this.locationDescription = response.data.data.first;
                            this.locationQueryAttribution = response.data.data.second;
                        }
                    });
                }
            },
            formatLocation(lat, lon) {
                const dmsCoords = new DmsCoordinates(lat, lon);
                return dmsCoords.toString();
            },
            removeLocation(index) {
                this.locations.splice(index, 1);
            },
            async addNewImage(file) {
                let imageId = await this.saveImage(file);
                this.images.push(imageId);
            },
            imageAdded() {
                this.$refs.uploadImage.active = true;
            },
            removeImage(index) {
                if (confirm("Do you really want to permanently delete this image?")) {
                    this.deleteImage(this.images[index]);
                    this.images.splice(index, 1);
                }
            },
            listenLatLon(latLng) {
                this.newLocationLat = latLng.lat;
                this.newLocationLon = latLng.lng;
                this.$refs.locationsMap.clickMarker = [ this.newLocationLat, this.newLocationLon ];
            },
            latLonChange () {
                const v = [this.newLocationLat, this.newLocationLon];
                const [x, y] = v.map(this.parseDms);
                if (!isNaN(x) && !isNaN(y)) {
                    this.$refs.locationsMap.clickMarker = [ x, y ];
                }
            },
            removeGeoPoint(polygonIndex, pointIndex) {
                this.polygons[polygonIndex].points.splice(pointIndex, 1);
            },
            listenGeoLatLon(latLng, ctrlDown) {
                if (ctrlDown) {
                    this.polygons[this.polygonSelected].points.splice(-1, 1);
                    if (this.polygons[this.polygonSelected].points.length > 0) {
                        let p = this.polygons[this.polygonSelected].points[this.polygons[this.polygonSelected].points.length - 1];
                        this.$refs.geometryMap.clickMarker = [ p.lat, p.lon ];
                    } else {
                        this.$refs.geometryMap.clickMarker = [ 0, 0 ];
                    }
                } else {
                    this.polygons[this.polygonSelected].points.push({ lat: latLng.lat, lon: latLng.lng });
                }
            },
            addPolygon() {
                this.polygons.push({ points: [], selected: false, color: '#0087cc' });
                this.polygonSelected = this.polygons.length - 1;
                this.polygonSelect();
            },
            polygonSelect() {
                if (this.polygons.length > 0) {
                    this.polygons.forEach(p => { p.selected = false });
                    this.polygons[this.polygonSelected].selected = true;
                    //hack to redraw map: adding point and then immediately removing it
                    this.polygons[this.polygonSelected].points.push({ lat: 0, lon: 0 });
                    this.polygons[this.polygonSelected].points.splice(-1, 1);
                }
            },
            async addFile(file) {
                let result = await this.saveFile(file);
                if (result.code == 0) {
                    this.files.push(result.result);
                }
            },
            removeFile(index) {
                if (confirm("Do you really want to permanently delete this file?")) {
                    this.deleteFile(this.files[index]);
                    this.files.splice(index, 1);
                }
            },
            addNewFocusTarget() {
                this.focusTargets.push({
                    segment: 'PEOPLE',
                    characteristic: 'PROVISION',
                    situations: [],
                    newSituation: ""
                });
            },
            removeFocusTarget(index) {
                this.focusTargets.splice(index, 1);
            },
            addNewRisk() {
                this.risks.push({
                    segment: 'PEOPLE',
                    characteristic: 'PROVISION',
                    situations: [],
                    newSituation: ""
                });
            },
            removeRisk(index) {
                this.risks.splice(index, 1);
            },
            trimTN(text) {
                if (text == null) return null;
                let trimmed = text.trim();
                if (trimmed.length == 0) return null;
                return trimmed;
            },
            parseDms(dmsStr) {
                var dmsRe = /^(-?\d+(?:\.\d+)?)[°:d]?\s?(?:(\d+(?:\.\d+)?)['′ʹ:]?\s?(?:(\d+(?:\.\d+)?)["″ʺ]?)?)?\s?([NSEW]|с. ш.|в. д.|ю. ш.|з. д.)?/i;
                var output = NaN;
                var dmsMatch = dmsRe.exec(dmsStr);
                if (dmsMatch) {
                    var degrees = Number(dmsMatch[1]);
                    var minutes = typeof (dmsMatch[2]) !== "undefined" ? Number(dmsMatch[2]) / 60 : 0;
                    var seconds = typeof (dmsMatch[3]) !== "undefined" ? Number(dmsMatch[3]) / 3600 : 0;
                    var hemisphere = dmsMatch[4] || null;
                    if (hemisphere !== null && /[SW]|ю. ш.|з. д./i.test(hemisphere)) {
                        output = -Math.abs(degrees) - minutes - seconds;
                    } else {
                        output = degrees + minutes + seconds;
                    }
                }
                return output;
            },
            async loadAutocompleteValues() {
                let a = await this.getAutocompleteValues('INDUSTRY');
                if (a.data.code == 0) {
                    this.autocompleteIndustryValues = a.data.objects.filter(function(av) { return av != null });
                }
                a = await this.getAutocompleteValues('LOCATION_DESCRIPTION');
                if (a.data.code == 0) {
                    this.autocompleteLocationDescriptionValues = a.data.objects.filter(function(av) { return av != null });
                }
            },
            async loadServiceTypes() {
                let a = await this.getFunctionStructure();
                if (a.data.code == 0) {
                    let items = [];
                    a.data.data.infrastructures.forEach(infr => {
                        infr.functions.forEach(func => {
                            let serviceTypesGroup = [];
                            func.serviceTypes.forEach(st => {
                                serviceTypesGroup.push({ infrastructureName: infr.name, functionName: func.name, name: st.name });
                            });
                            items.push({ groupName: infr.name + " - " + func.name, serviceTypes: serviceTypesGroup });
                        });
                    });
                    this.functionServiceTypesOptions = items;
                }
            }
        }
    }
</script>
<style scoped>
    >>>.v-md-editor-preview p {
        color: #000;
        font-size: 14px;
    }
</style>