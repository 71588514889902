<template>
    <div>
        <b-row>
            <b-col>
                <h1>{{ $t("menu.solutions.list")}}</h1>
            </b-col>
            <b-col class="flex-row-reverse">
                <b-button variant="success" to="/admin/create_solution"><i class="fa fa-file"></i> {{ $t("menu.solutions.create")}}</b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-table id="solutions-table" striped hover
                         :items="itemProvider"
                         :per-page="15"
                         :fields="fields"
                         ref="table"
                >
                    <template v-slot:cell(timestamp)="data">
                        {{ dateFormatted(data.value) }}
                    </template>
                    <template v-slot:cell(rating)="row">
                        {{ row.item.expertOpinion.rating }}
                    </template>
                    <template v-slot:cell(actions)="row">
                        <b-button size="sm" @click="showSolutionClicked(row.item)" variant="outline-secondary" class="mr-1">
                            <i class="fa fa-eye"></i> {{ $t("forms.show")}}
                        </b-button>
                        <b-button size="sm" @click="editSolutionClicked(row.item)" variant="secondary" class="mr-1">
                            <i class="fa fa-edit"></i> {{ $t("forms.edit")}}
                        </b-button>
                        <b-button size="sm" @click="deleteSolutionClicked(row.item)" variant="warning" class="mr-1">
                            <i class="fa fa-stop-circle"></i> {{ $t("forms.delete")}}
                        </b-button>
                    </template>
                </b-table>
                <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="15"
                        @change="updateData()"
                        aria-controls="solutions-table"
                ></b-pagination>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import SolutionAPI from "../../mixins/SolutionAPI";
    import storage from '../../storage';

    export default {
        name: "SolutionList",
        data() {
            return {
                currentPage: 1,
                rows: 0,
                fields: ["id", "timestamp", "shortName", "rating", "actions"]
            }
        },
        mixins: [SolutionAPI],
        methods: {
            async itemProvider() {
                return (await this.find({ page: this.currentPage - 1, size: 15 })).data.objects;
            },
            async updateData() {
                let a = await this.count({});
                if (a.data.code == 0) {
                    this.rows = a.data.number;
                }
                if (this.$refs.table != undefined) {
                    this.$refs.table.refresh();
                }
            },
            showSolutionClicked(item) {
                this.$router.push("/solution/" + item.id);
            },
            editSolutionClicked(item) {
                this.$router.push("/admin/edit_solution/" + item.id);
            },
            async deleteSolutionClicked(item) {
                if (confirm("Do you really want to delete this solution?")) {
                    await this.deleteSolution(item.id);
                    this.updateData();
                }
            },
            dateFormatted(date) {
                return new Date(date).toLocaleDateString(storage.getters.getLocale);
            }
        },
        async mounted() {
            this.updateData();
        }
    }
</script>
<style scoped>
</style>