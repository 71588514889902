<template>
    <b-navbar toggleable="md" type="light" variant="light" class="p-2" fixed="top">
        <b-container class="p-0" style="">
            <b-navbar-brand to="/">
                <b-img :src="require('../assets/topLogo.svg')"></b-img>
            </b-navbar-brand>
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav fill align="center" class="mx-auto">
                    <b-nav-item to="/about" v-bind:class="[{ active: $route.path == '/about' }, 'mr-2']">{{ $t('nav.about') }}</b-nav-item>
                    <b-nav-item to="/experts" v-bind:class="[{ active: $route.path == '/experts' }, 'mr-2']">{{ $t('nav.experts') }}</b-nav-item>
                    <b-nav-item v-if="currentUser.isAdmin === true" to="/admin" v-bind:class="{ active: $route.path.startsWith('/admin') }">{{ $t('nav.admin') }}</b-nav-item>
                </b-navbar-nav>
            </b-collapse>
            <b-navbar-nav class="ml-auto" align="center">
                <b-nav-text v-if="currentUser.name !== undefined" class="my-auto mr-3">
                    <b-avatar v-if="currentUser.avatar != null && currentUser.avatar != ''" size="2rem" class="mr-1" :src="getImageUrl(currentUser.avatar)"></b-avatar>
                    {{ currentUser.name }}
                </b-nav-text>
                <b-nav-item v-if="currentUser.name !== undefined">
                    <b-button variant="dark" class="logoutin-btn logout-btn" type="button" @click="logout()"  style="margin-right: -0.5rem">
                        <b-img :src="require('../assets/icons/exit.svg')" ></b-img>
                    </b-button>
                </b-nav-item>
                <b-nav-item v-else style="margin-right: -0.5rem">
                    <b-button id="login" variant="secondary" class="logoutin-btn" href="#" tabindex="0">
                        <b-img :src="require('../assets/icons/lock.svg')"></b-img>
                    </b-button>
                    <b-popover target="login" triggers="click blur" placement="bottomleft" custom-class="test-class">
                        <b-form-row class="p-0 m-0">
                            <b-form-input style="width: 230px" size="sm" v-model="userLogin" type="text" :placeholder="$t('login')"></b-form-input>
                        </b-form-row>
                        <b-form-row class="p-0 ml-0 mr-0 mb-0" style="margin-top: 8px;">
                            <b-form-input size="sm"
                                          v-model="userPassword"
                                          type="password"
                                          aria-describedby="password-feedback"
                                          :state="isPasswordCorrect"
                                          :placeholder="$t('password')"></b-form-input>
                            <b-form-invalid-feedback id="password-feedback">
                              {{$t('login_failed') }}
                            </b-form-invalid-feedback>
                        </b-form-row>
                        <b-form-row class="p-0" style="margin-top: 16px;">
                            <b-button variant="primary" style="margin: auto" type="button" @click="login()">{{ $t('log_in') }}</b-button>
                        </b-form-row>
                    </b-popover>
                </b-nav-item>
            </b-navbar-nav>
        </b-container>
    </b-navbar>
</template>
<script>
    import Axios from "../axios.js";
    import ImageAPI from "../mixins/ImageAPI";
    import storage from '../storage';

    export default {
        name: "NavBar",
        mixins: [ImageAPI],
        computed: {
            currentUser() {
                return {
                    avatar: storage.getters.getUserAvatar,
                    name: storage.getters.getUsername,
                    isAdmin: storage.getters.hasAuthority('SERVER_ADMIN')
                };
            }
        },
        methods: {
            login() {
                Axios.post(process.env.VUE_APP_API_URL + `/api/v1/auth/login`, {'email': this.userLogin, 'password': this.userPassword})
                .then(response => {
                        if (response.data.code === 0) {
                            this.isPasswordCorrect = true;
                            this.$store.dispatch('login', {
                                'token': response.data.token,
                                'user': response.data.user
                            });
                        } else if (response.data.code === 102) {
                            this.isPasswordCorrect = false;
                        }
                    }
                )
            },
            logout() {
                this.$store.dispatch('logout');
            }
        },
        data() {
            return {
                userLogin: null,
                userPassword: null,
                isPasswordCorrect: null
            }
        }
    }
</script>
<style scoped>
    .navbar.navbar-light {
        background-color : #F4F4F5 !important;
    }

    .navbar-brand {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 18px;
        padding: 0;
    }

    .navbar-light .navbar-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;

        color: #000000;
    }

    .navbar-light .active > .nav-link {
        color: #C4C4C4 !important;
    }

    .logoutin-btn {
        padding: 4px;
        width: 36px;
        height: 36px;
        border: none;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
    }

    .logoutin-btn:focus {
        background: #074A74;
    }

    .logout-btn {
        color: white;
        background-color: #074A74;
    }

    .logout-btn:hover {
        background: #0087CC;
    }

    @media screen and (max-width: 767px) {
        .navbar-brand {
            margin-left: 0px;
        }
        .logoutin-btn {
            margin-right: 10px;
            float: right;
        }
    }

    .btn-primary {
        background: #074A74;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
        border: none;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        padding: 10px 40px;
    }

    .btn-primary:hover {
        background: #0087CC;
    }

    input.form-control {
        background: #FFFFFF;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        border: none;
    }

    input.form-control::placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #C4C4C4;
    }
</style>
<style>
    .test-class > .popover-body {
        padding: 20px 20px 16px 20px;
    }

    .test-class {
        border: none !important;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
    }

    .bs-popover-bottom > .arrow::before {
        border: none !important;
    }

    .bs-popover-bottom {
        margin-top: 0.75rem !important;
    }

    .popover .arrow {
        width: 2.0rem !important;
        height: 0.75rem !important;
        overflow: hidden;
        top: -0.75rem !important;
    }

    .popover .arrow:after {
        content: "" !important;
        position: absolute !important;
        width: 1rem;
        height: 1rem;
        background: #fff;
        transform: rotate(45deg);
        top: 0.25rem !important;
        left: calc(0.5rem - 2px);
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.075);
    }
</style>