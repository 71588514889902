<template>
    <b-row>
        <b-col cols="6">
            <b-card class="mb-2">
                <b-card-title>
                    {{$t('user.edit') + ' ' + id}}
                </b-card-title>
                <b-form @submit="onUserUpdate">
                    <b-card-body>
                        <b-row class="mb-2">
                            <b-img v-if="user.avatar != null" rounded="circle" class="mw-100" :src="getImageUrl(user.avatar)"></b-img>
                            <br>
                            <file-upload
                                    extensions="gif,jpg,jpeg,png,webp"
                                    accept="image/png,image/gif,image/jpeg,image/webp"
                                    name="avatar"
                                    v-bind:customAction="addAvatar"
                                    :active="true"
                                    @input-file="avatarAdded"
                                    ref="uploadAvatar"
                            >
                                <p class="text-success ml-1">
                                    {{ user.avatar == null ? $t('user.addAvatar') : $t('user.changeAvatar') }}
                                </p>
                            </file-upload>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col>
                                {{ $t("forms.email")}}
                            </b-col>
                            <b-col>
                                <input type="text" readonly class="form-control-plaintext" v-bind:value="user.email">
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col>
                                <label for="newNameInput">{{ $t("forms.display_name")}}</label>
                            </b-col>
                            <b-col>
                                <b-form-input
                                        id="newNameInput"
                                        v-model="user.name"
                                        type="text"
                                        required
                                        :placeholder="$t('forms.name')"
                                ></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col>
                                <label for="newPositionInput">{{ $t("forms.position")}}</label>
                            </b-col>
                            <b-col>
                                <b-form-input
                                        id="newPositionInput"
                                        v-model="user.position"
                                        type="text"
                                        :placeholder="$t('forms.position')"
                                ></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col>
                                <label for="newBioInput">{{ $t("forms.biography")}}</label>
                            </b-col>
                            <b-col>
                                <b-form-textarea
                                        id="newBioInput"
                                        v-model="user.biography"
                                        type="text"
                                ></b-form-textarea>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col>
                                <label for="passwordInput">{{ $t("menu.roles")}}</label>
                            </b-col>
                            <b-col>
                                <b-form-select v-model="user.roleIds"
                                               :options="availableRoles"
                                               multiple
                                ></b-form-select>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-button type="submit" variant="primary">{{ $t("forms.save_changes")}}</b-button>
                </b-form>
            </b-card>
        </b-col>
        <b-col>
            <b-form @submit="onPasswordUpdate">
                <b-card>
                    <b-card-title>
                        {{$t('user.change_password')}}
                    </b-card-title>
                    <b-card-body>
                        <b-row class="mb-1">
                            <b-col>
                                {{ $t("forms.new_password")}}
                            </b-col>
                            <b-col>
                                <b-form-input
                                        id="newPasswordInput"
                                        v-model="newPassword"
                                        type="password"
                                        required
                                        :placeholder="$t('user.password.placeholder')"
                                ></b-form-input>
                                <span v-if="!$v.newPassword.minLength" class="text-danger">{{ $t("forms.at_least_chars", {chars: 4})}}</span>
                            </b-col>
                        </b-row>
                        <b-row class="mb-1">
                            <b-col>
                                {{ $t("forms.confirm_password")}}
                            </b-col>
                            <b-col>
                                <b-form-input
                                        id="newPasswordConfirmation"
                                        v-model="newPasswordConfirmation"
                                        type="password"
                                        required
                                        :placeholder="$t('user.repeat_password.placeholder')"
                                ></b-form-input>
                                <span v-if="!$v.newPasswordConfirmation.sameAsPassword" class="text-danger">
                                {{ $t("forms.passwords_do_not_match")}}</span>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-button type="submit" variant="primary">{{ $t("user.change_password")}}</b-button>
                </b-card>
            </b-form>
        </b-col>
    </b-row>
</template>

<script>
import UserAPI from "../../mixins/UserAPI";
import RoleAPI from "../../mixins/RoleAPI";
import ImageAPI from "../../mixins/ImageAPI";
import FileUpload from 'vue-upload-component';
import {minLength, required, sameAs} from 'vuelidate/lib/validators'

export default {
        name: "EditUser",
        props: ['id'],
        mixins: [UserAPI, RoleAPI, ImageAPI],
        components: {
            FileUpload
        },
        data() {
            return {
                user: {
                    avatar: null,
                    position: null,
                    biography: null,
                    email: "",
                    name: "",
                    roles: [],
                    roleIds: []
                },
                newPassword: null,
                newPasswordConfirmation: null,
                availableRoles: []
            }
        },
        validations: {
            newPassword: {
                required,
                minLength: minLength(4)
            },
            newPasswordConfirmation: {
                required,
                minLength: minLength(4),
                sameAsPassword: sameAs('newPassword')
            },
        },
        async mounted() {
            this.availableRoles = (await this.getRoles()).map(i => {return {text: i.name, value: i.id}});
            this.user = await this.getUser(this.id);
            this.user.roleIds = this.user.roles.map((role) => {
                return role.id;
            })
        },
        methods: {
            async onUserUpdate(evt) {
                evt.preventDefault();
                let request = {
                    newAvatar: this.user.avatar,
                    newPosition: this.user.position,
                    newBiography: this.user.biography,
                    newName: this.user.name,
                    newRoles: this.user.roleIds
                };
                let result = await this.updateUser(this.id, request, this.isEditingSelf());
                if (result) {
                    this.$router.push('/admin/users')
                }
            },
            isEditingSelf() {
                let currentUserId = this.$store.getters.getUserId + '';
                let thisUserId = this.id;
                return thisUserId === currentUserId;
            },
            async onPasswordUpdate(evt) {
                evt.preventDefault();
                this.$v.$touch();
                if (this.$v.newPasswordConfirmation.$invalid || this.$v.newPassword.$invalid) {
                    return;
                }
                this.updateUser(this.id, {'newPassword': this.$data.newPassword}, this.isEditingSelf());
            },
            async addAvatar(file) {
                let imageId = await this.saveImage(file);
                if (imageId != "" && this.user.avatar != null) {
                    this.deleteImage(this.user.avatar);
                }
                this.user.avatar = imageId;
            },
            avatarAdded() {
                this.$refs.uploadAvatar.active = true;
            }
        }
    }
</script>
<style scoped>
</style>