<template>
  <b-container>
    <NavBar></NavBar>
    <b-row class="mb-3">
      <b-col>
        <h1 class="text-center">{{ $t('nav.about') }}</h1>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-img  :src="img" fluid-grow></b-img>
    </b-row>
    <b-row class="mb-3">
      <p>
        Сервис является базой отобранных лучших практик и решений в области умного города и урбанистики,
        хорошо зарекомендовавших себя при реальном внедрении или имеющих высокий потенциал при реализации.
        Каждое решение – это проект или практика, изменяющая свойства городской среды и способствующая
        повышению качества городской среды и качества жизни горожан.
      </p>
      <p>
        Отличительными чертами сервиса являются:
      </p>
      <ol>
        <li>
          Ориентация на человека. Мы следим за тем, чтобы каждое решение или практика для преобразования городской среды
          обоснованно улучало условия жизни людей в конкретных жизненных ситуациях.
        </li>
        <li>
          Экспертиза. Мы приглашаем экспертов в области умного города и урбанистики для формирования
          заключений относительно представленных решений и практик.
        </li>
        <li>
          Открытость. Мы ведем постоянное обновление нашей базы. Участие в этом процессе принимают все желающие.
        </li>
      </ol>
      <p>
        Наши контакты:
        <br>
        <a href="https://idu.itmo.ru">https://idu.itmo.ru</a>
        <br>
        <a href="mailto:idu@itmo.ru">idu@itmo.ru</a>
      </p>
    </b-row>
    <b-row class="mb-5">
      <b-col>
        <b-button to="/" class="float-right">{{ $t('toMainPage') }}</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import NavBar from "../components/NavBar";
import SCM_About from "../assets/SCM_about.jpg"
export default {
  name: "About",
  components: { NavBar },
  data() {
    return {
      img: SCM_About
    }
  }
}
</script>
<style scoped>
</style>