import VueRouter from 'vue-router'
import Dashboard from "./pages/Dashboard";
import SignIn from "./pages/SignIn";
import AdminMain from "./pages/AdminMain";
import UserList from "./pages/users/UserList";
import Forbidden from "./pages/errors/Forbidden";
import Vue from 'vue'
import store from './storage.js';
import ProfilePage from "./pages/users/ProfilePage";
import NotFound from "./pages/errors/NotFound";
import ServerLogs from "./pages/admin/ServerLogs";
import CreateUser from "./pages/users/CreateUser";
import RoleList from "./pages/roles/RoleList";
import CreateRole from "./pages/roles/CreateRole";
import EditRole from "./pages/roles/EditRole";
import EditUser from "./pages/users/EditUser";
import Register from "@/pages/Register";
import OAuthSignIn from "@/pages/OAuthSignIn";
import Solutions from "@/pages/solutions/Solutions";
import SolutionPage from "@/pages/solutions/SolutionPage";
import SolutionList from "@/pages/solutions/SolutionList";
import CreateEditSolution from "@/pages/solutions/CreateEditSolution";
import About from "@/pages/About";
import Experts from "@/pages/users/ExpertsPage";

Vue.use(VueRouter);

const router = new VueRouter({
    routes:  [
        {
            path: '/admin',
            component: AdminMain,
            children: [{component: Dashboard, path: ''}],
            meta: {
                requiresPermissions: []
            }
        },
        {
            path: '/admin/profile',
            component: AdminMain,
            children: [{component: ProfilePage, path: ''}],
            meta: {
                requiresPermissions: []
            }
        },
        {
            path: '/admin/users/:id',
            component: AdminMain,
            children: [{component: EditUser, path: '', props: true}],
            meta: {
                requiresPermissions: ['MANAGE_USERS']
            }
        },
        {
            path: '/admin/users',
            component: AdminMain,
            children: [{component: UserList, path: ''}],
            meta: {
                requiresPermissions: ['MANAGE_USERS']
            }
        },
        {
            path: '/admin/create_user',
            component: AdminMain,
            children: [{component: CreateUser, path: ''}],
            meta: {
                requiresPermissions: ['MANAGE_USERS']
            }
        },
        {
            path: '/admin/activateUser', component: SignIn
        },
        {
            path: '/admin/roles/:id',
            component: AdminMain,
            children: [
                {
                    component: EditRole, path: '', props: true
                }],
            meta: {
                requiresPermissions: ['MANAGE_ROLES']
            }
        },
        {
            path: '/admin/roles',
            component: AdminMain,
            children: [
                {
                    component: RoleList, path: ''
                }],
            meta: {
                requiresPermissions: ['MANAGE_ROLES']
            }
        },
        {
            path: '/admin/create_role',
            component: AdminMain,
            children: [{component: CreateRole, path: ''}],
            meta: {
                requiresPermissions: ['MANAGE_ROLES']
            }
        },
        {
          path: "/admin/server_logs",
            component: AdminMain,
            children: [{component: ServerLogs, path: ''}],
            meta: {
                requiresPermissions: ['SERVER_ADMIN']
            }
        },
        {
            path: '/signin', component: SignIn
        },
        {
            path: `/oauthLogin`, component: OAuthSignIn
        },
        {
            path: "/register", component: Register
        },
        {
            path: '/', component: Solutions
        },
        {
            path: '/solution/:id', component: SolutionPage,
            props: true
        },
        {
            path: '/admin/solutions',
            component: AdminMain,
            children: [{component: SolutionList, path: ''}],
            meta: {
                requiresPermissions: ['MANAGE_SOLUTIONS']
            }
        },
        {
            path: '/admin/create_solution',
            component: AdminMain,
            children: [{component: CreateEditSolution, path: ''}],
            meta: {
                requiresPermissions: ['MANAGE_SOLUTIONS']
            }
        },
        {
            path: '/admin/edit_solution/:id',
            component: AdminMain,
            children: [{component: CreateEditSolution, props: true, path: ''}],
            meta: {
                requiresPermissions: ['MANAGE_SOLUTIONS']
            }
        },
        {
            path: '/about', component: About
        },
        {
            path: '/experts', component: Experts
        },
        {
            path: '/403', component: Forbidden
        },
        {
            path: "/404", component: NotFound
        },
        {
            path: '*',
            redirect: '/404'
        }
    ]
});

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresPermissions !== undefined)) {
        if (store.state.token === undefined || store.state.token === "") {
            next({
                path: '/signin',
                params: { nextUrl: to.fullPath }
            })
        } else {
            let authorities = store.getters.getAuthorities;
            if(to.matched.some(record => record.meta.requiresPermissions.every(permission => authorities.includes(permission)))) {
                next();
            }else {
                next({
                    path: '/403'
                })
            }
        }
    } else {
        next()
    }
});

export default router