<template>
    <b-link class="card-link" :href="'#/solution/' + this.solution.id">
        <b-card no-body style="min-width: 200px; cursor: pointer; box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);">
            <b-tooltip v-if="solution.shortName.length > 15" noninteractive :target="() => $refs['sp']" triggers="hover">
                {{ solution.shortName }}
            </b-tooltip>
            <b-tooltip v-if="solution.locationDescription != null && solution.locationDescription.length > 35" noninteractive :target="() => $refs['lp']" triggers="hover">
                {{ solution.locationDescription }}
            </b-tooltip>
            <b-card-body class="p-0">
                <div style="position: absolute; top: 0; right: 16px;">
                   <SolutionTypeFlag :st="solution.solutionType"></SolutionTypeFlag>
                </div>
                <b-card-text>
                    <div class="custom-card-image" :style="'background-image: url(' +  getImageUrl(solution.image) + ')'"></div>
                    <b-row class="m-0 pr-2 pl-2" style="display: table; width: 100%">
                        <div style="width: 100%; height: 3rem">
                            <p ref="sp" class="ml-1 hov line-clamp-2 b-0 mb-0" style="position: absolute; width: calc(100% - 96px); min-height: 3rem">{{ solution.shortName }}</p>
                            <div cols="1" class="m-0 ml-auto" style="position: absolute; top: 141px; right: 6px; width: 68px">
                                <span v-if="solution.commentsCount > 0" style="font-size: 11px; line-height: 13px" class="mr-2">
                                    {{ solution.commentsCount }}
                                    <b-img :src="require('../assets/icons/comments.svg')"  style="margin: 0px 0px 0px 2px"></b-img>
                                </span>
                                <span style="font-size: 11px; line-height: 13px">
                                    {{ solution.rating.toFixed(1) }}
                                    <b-img :src="require('../assets/icons/grey_star.svg')" style="margin: -3px 0px 0px 0px"></b-img>
                                </span>
                            </div>
                        </div>
                    </b-row>
                    <b-row class="m-2">
                        <b-col v-if="solution.locationDescription != null" style="white-space: nowrap;" class="p-0 ml-1">
                            <span ref="lp" class="float-left text-truncate" style="max-width: calc(200% - 60px); font-size: 11px">{{ solution.locationDescription }}</span>
                        </b-col>
                        <b-col class="p-0 mr-1"><span class="float-right" style="font-size: 11px">{{ dateFormatted }}</span></b-col>
                    </b-row>
                </b-card-text>
            </b-card-body>
        </b-card>
    </b-link>
</template>
<script>
    import ImageAPI from "../mixins/ImageAPI";
    import SolutionTypeFlag from "./SolutionTypeFlag";
    import storage from '../storage';

    export default {
        name: "SolutionCard",
        props: ["solution"],
        mixins: [ImageAPI],
        components: {
            SolutionTypeFlag
        },
        computed: {
            dateFormatted() {
                return this.solution.timestamp.toLocaleDateString(storage.getters.getLocale);
            }
        },
        methods: {
            getColorTheme(solutionType) {
                switch (solutionType) {
                    case 'BUSINESS_SOLUTION':
                        return { clr: '#7B61FF', icon: 'fas fa-fw fa-lg fa-briefcase'};
                    case 'BEST_PRACTICE':
                        return { clr: '#FF951A', icon: 'fas fa-fw fa-lg fa-star'};
                    case 'PROJECT':
                        return { clr: '#009DFF', icon: 'fas fa-fw fa-lg fa-drafting-compass'};
                    default:
                        return { clr: '#1B9E48', icon: 'fas fa-fw fa-lg fa-lightbulb'};
                }
            }
        },
        mounted() {
        }
    }
</script>
<style scoped>
    .custom-card-image {
        border-radius: 8px 8px 0 0;
        height: 141px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain auto;
    }

    a {
        color: #808080;
    }

    a:hover {
        color: #808080;
        text-decoration: none;
    }

    .card {
        border-radius: 8px;
        border: none;
    }

    .hov {
        color: #000;
    }

    .card:hover > .card-body > .card-text > .row > .hov {
        color: #0087CC;
    }

    .line-clamp-2 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
</style>