<template>
    <b-row>
        <b-col cols="6">
            <b-card class="mb-2">
                <b-card-title>
                    {{$t('menu.users.create')}}
                </b-card-title>
                <b-form @submit="onUserCreate">
                    <b-card-body>
                        <b-row class="mb-2">
                            <b-img v-if="avatar != null" rounded="circle" :src="getImageUrl(avatar)"></b-img><br>
                            <file-upload
                                    extensions="gif,jpg,jpeg,png,webp"
                                    accept="image/png,image/gif,image/jpeg,image/webp"
                                    name="avatar"
                                    v-bind:customAction="addAvatar"
                                    :active="true"
                                    @input-file="avatarAdded"
                                    ref="uploadAvatar"
                            >
                                <p class="text-success ml-1">
                                    {{ avatar == null ? $t('user.addAvatar') : $t('user.changeAvatar') }}
                                </p>
                            </file-upload>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col>
                                <label for="emailInput">{{ $t("forms.email")}}<sup>*</sup></label>
                            </b-col>
                            <b-col>
                                <b-form-input
                                        id="emailInput"
                                        v-model="email"
                                        type="email"
                                        required
                                        :placeholder="$t('forms.email')"
                                ></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col>
                                <label for="newNameInput">{{ $t("forms.display_name")}}</label>
                            </b-col>
                            <b-col>
                                <b-form-input
                                        id="newNameInput"
                                        v-model="name"
                                        type="text"
                                        required
                                        :placeholder="$t('forms.name')"
                                ></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col>
                                <label for="newPositionInput">{{ $t("forms.position")}}</label>
                            </b-col>
                            <b-col>
                                <b-form-input
                                        id="newPositionInput"
                                        v-model="position"
                                        type="text"
                                        :placeholder="$t('forms.position')"
                                ></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col>
                                <label for="newBioInput">{{ $t("forms.biography")}}</label>
                            </b-col>
                            <b-col>
                                <b-form-textarea
                                        id="newBioInput"
                                        v-model="biography"
                                        type="text"
                                ></b-form-textarea>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col>
                                <label for="passwordInput">{{ $t("forms.password")}}<sup>*</sup></label>
                            </b-col>
                            <b-col>
                                <b-form-input
                                        id="newNameInput"
                                        v-model="password"
                                        type="password"
                                        required
                                        :placeholder="$t('forms.password')"
                                ></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col>
                                <label for="passwordInput">{{ $t("menu.roles")}}</label>
                            </b-col>
                            <b-col>
                                <b-form-select v-model="roles"
                                               :options="availableRoles"
                                               multiple
                                ></b-form-select>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-button type="submit" variant="primary">{{ $t("menu.users.create")}}</b-button>
                </b-form>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
    import UserAPI from "../../mixins/UserAPI";
    import RoleAPI from "../../mixins/RoleAPI";
    import ImageAPI from "../../mixins/ImageAPI";
    import FileUpload from 'vue-upload-component';
    import toastMixin from "../../mixins/toast";

    export default {
        name: "CreateUser",
        mixins: [UserAPI, RoleAPI, ImageAPI, toastMixin],
        components: {
            FileUpload
        },
        data() {
            return {
                email: null,
                name: null,
                avatar: null,
                position: null,
                biography: null,
                password: null,
                roles: [],
                availableRoles: []
            }
        },
        async mounted() {
           this.availableRoles = (await this.getRoles()).map(i => {return {text: i.name, value: i.id}})
        },
        methods: {
            async onUserCreate(evt) {
                evt.preventDefault();
                let request = {
                    name: this.name,
                    avatar: this.avatar,
                    position: this.position,
                    biography: this.biography,
                    email: this.email,
                    password: this.password,
                    roles: this.roles
                };

                let result = await this.registerUser(request);
                if (result) {
                    this.$router.push('/admin/users')
                }
            },
            async addAvatar(file) {
                let imageId = await this.saveImage(file);
                if (imageId != "" && this.avatar != null) {
                    this.deleteImage(this.avatar);
                }
                this.avatar = imageId;
            },
            avatarAdded() {
                this.$refs.uploadAvatar.active = true;
            }
        }
    }
</script>
<style scoped>
</style>