<template>
    <div>
        <NavBar></NavBar>
        <div id="wrapper">
            <SidePanel></SidePanel>
            <div id="content-wrapper" class="d-flex flex-column">
                <div id="content">
                    <div class="container-fluid">
                        <router-view ref="child"></router-view>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import("../../public/sbadmin/css/sb-admin-2.css");

    import NavBar from "../components/NavBar";
    import SidePanel from "../components/SidePanel";

    export default {
        name: "AdminMain",
        components: {NavBar, SidePanel},

        beforeRouteEnter (to, from, next) {
            next(vm => {
                // hack: use this hardcoded method to force refresh chld component data when its shown again
                // since vue-router does not recreate components but reuses old ones
                if (vm.$refs.child && vm.$refs.child.updateData !== undefined) {
                    vm.$refs.child.updateData()
                }
            })
        }
    }
</script>
<style scoped>
</style>