<template>
    <div class="map-container">
        <l-map
                ref="aMap"
                :zoom="zoom"
                :center="center"
                @update:zoom="zoomUpdated"
                @update:center="centerUpdated"
                @click="addMarker">
            <l-tile-layer :url="tileUrl" :attribution="attribution"></l-tile-layer>
            <v-marker-cluster v-if="clusters">
                <l-marker v-for="(marker, index) in this.markers" v-bind:key="index" :lat-lng="[marker.lat, marker.lon]">
                    <l-popup v-bind:content="getMarkerTooltip(marker)" v-bind:options="{offset:[-1, 18]}"></l-popup>
                </l-marker>
            </v-marker-cluster>
            <l-marker v-else v-for="(marker, index) in this.markers" v-bind:key="index" :lat-lng="[marker.lat, marker.lon]"></l-marker>
            <l-marker v-if="!(clickMarker[0] == 0 && clickMarker[1] == 0)" :lat-lng="clickMarker"></l-marker>
            <l-polygon
                    v-for="(polygon, index) in this.polygons"
                    v-bind:key="index"
                    :lat-lngs="polygon.points"
                    :color="polygon.color"
                    :fillColor="polygon.color"
                    :fillOpacity="polygon.selected ? 0.3 : 0.1"
                    :dashArray="polygon.selected ? '8' : '0'"
                    :weight="polygon.selected ? 4 : 2"
            >
                <l-popup class="p-2" v-if="!clickable">{{ polygon.name }}</l-popup>
            </l-polygon>
        </l-map>
    </div>
</template>
<script>
    import ImageAPI from "../mixins/ImageAPI";
    import {LMap, LTileLayer, LMarker, LPolygon, LPopup} from 'vue2-leaflet';
    import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
    import 'leaflet/dist/leaflet.css';
    import 'leaflet.markercluster/dist/MarkerCluster.css';

    export default {
        name: "LeafletMap",
        props: ["markers", "polygons", "clickable", "clusters"],
        mixins: [ImageAPI],
        components: {
            LMap,
            LTileLayer,
            LMarker,
            LPolygon,
            LPopup,
            'v-marker-cluster': Vue2LeafletMarkerCluster
        },
        data() {
            return {
                tileUrl : 'https://{s}.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png',
                attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                zoom: 13,
                center: [59.9362145, 30.3024282],
                clickMarker: [0, 0]
            }
        },
        methods: {
            zoomUpdated(zoom) {
                this.zoom = zoom;
            },
            centerUpdated(center) {
                this.center = center;
            },
            addMarker(event) {
                if (this.clickable == undefined || this.clickable != "true") return;
                let ctrl = event.originalEvent.ctrlKey;
                if (!ctrl) {
                    this.clickMarker = [event.latlng.lat, event.latlng.lng];
                }
                this.$emit('listenLatLon', event.latlng, ctrl);
            },
            updateBounds(points) {
                if (points.length > 1) {
                    this.$refs.aMap.mapObject.fitBounds(points, {maxZoom: 15});
                } else if (points.length == 1) {
                    this.center = [points[0].lat, points[0].lon];
                }
            },
            getMarkerTooltip(marker) {
                var content = '<a class="solution-tooltip" href="#/solution/' + marker.sID + '">';
                content += '<div class="solution-avatar" style="background-image: url(' +
                    this.getImageUrl(marker.sImage) + '); height: ' +
                    (marker.sShortName.length > 16 ? '108' : '90') + 'px"></div>';
                content += '<div class="solution-description">';
                content += '<h1 class="line-clamp-2">' + marker.sShortName + '</h1>\n';
                content += marker.sCommentsCount + ' <img src="' + require('../assets/icons/comments.svg') + '" style="margin-right: 10px"></img>';
                content += marker.sRating + ' <img src="' + require('../assets/icons/grey_star.svg') + '" style="margin-top: -3px"></img>';
                if (marker.sLocationDescription != null) {
                    content += '<p class="p-0 m-0 text-truncate" style="margin-top: 9px !important">' + marker.sLocationDescription + '</p>';
                }
                content += '</div>';
                content += '</a>';
                return content;
            }
        },
        mounted() {
            if (this.markers != null) {
                this.updateBounds(this.markers);
            } else if (this.polygons != null) {
                this.updateBounds(this.polygons.flatMap(p => p.points));
            }
        }
    }
</script>
<style scoped>
    >>>.marker-cluster {
        background-clip: padding-box;
        border-radius: 16px;
        background-color: #074a74;
    }
    >>>.marker-cluster-small {
        width: 24px !important;
        height: 24px !important;
    }
    >>>.marker-cluster-medium {
        width: 28px !important;
        height: 28px !important;
    }
    >>>.marker-cluster-large {
        width: 32px !important;
        height: 32px !important;
    }

    >>>.marker-cluster div {
        margin-left: 2px;
        margin-top: 2px;
        text-align: center;
        border-radius: 14px;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
    }
    >>>.marker-cluster-small div {
        width: 20px;
        height: 20px;
    }
    >>>.marker-cluster-medium div {
        width: 24px;
        height: 24px;
    }
    >>>.marker-cluster-large div {
        width: 28px;
        height: 28px;
    }

    >>>.marker-cluster-small span {
        line-height: 20px;
    }
    >>>.marker-cluster-medium span {
        line-height: 24px;
    }
    >>>.marker-cluster-large span {
        line-height: 28px;
    }

    >>>.leaflet-popup-tip {
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
    }
    >>>.leaflet-popup-content-wrapper {
        font-family: 'Roboto', sans-serif;
        padding: 0px;
        border-radius: 5px;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
    }
    >>>.leaflet-popup-content {
        margin: 0px;
        display: table;
    }
    >>>.solution-tooltip {
        display: inline-block;
        width: 269px;
    }
    >>>.solution-tooltip:hover {
        text-decoration: none;
    }
    >>>.solution-tooltip > .solution-avatar {
        display: inline-block;
        float: left;
        width: 89px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain auto;
        border-radius: 5px 0 0 5px;
    }
    >>>.solution-tooltip > .solution-description {
        display: inline-block;
        width: 180px;
        padding: 12px 19px 7px 16px;
        font-size: 11px;
        color: #808080;
    }
    >>>.solution-tooltip > .solution-description > h1 {
        font-size: 14px;
        color: #000;
        margin-bottom: 16px;
    }
    >>>.solution-tooltip:hover > .solution-description > h1 {
        color: #0087cc;
    }
    >>>.line-clamp-2 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    >>>.leaflet-popup-close-button {
        display: none;
    }
</style>