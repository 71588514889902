import Axios from '../axios'
import toastMixin from "./toast";

const ImageAPI = {
    methods: {
        async saveImage(imageFile) {
            let formData = new FormData();
            formData.append("image", imageFile.file);
            let response = await Axios.post(process.env.VUE_APP_API_URL + `/api/v1/images/save`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.data.code === 0) {
                this.successToast(`Image uploaded`);
                return response.data.result;
            }

            this.errorToast(`Failed to upload image: ` + response);
            return ""
        },

        getImageUrl(imageId) {
            if (imageId == '') {
                return process.env.VUE_APP_API_URL + '/api/v1/images/no_image'
            }
            return process.env.VUE_APP_API_URL + '/api/v1/images/' + imageId
        },

        async deleteImage(id) {
            return await Axios.delete(process.env.VUE_APP_API_URL + `/api/v1/images/delete/${id}`);
        }
    },
    mixins: [toastMixin]
};

export default ImageAPI