//i18n-setup.js
// copied from documentation https://kazupon.github.io/vue-i18n/guide/lazy-loading.html
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import en from './lang/en'
import ru from './lang/ru'
import storage from './storage'

Vue.use(VueI18n);

let messages = {
    en,
    ru
};

let savedLocale = storage.getters.getLocale;

export const i18n = new VueI18n({
    locale: savedLocale, // set locale
    fallbackLocale: 'ru',
    messages, // set locale messages
    pluralizationRules: {
        'ru': function(choice, choicesLength) {
            if (choice === 0) {
                return 0;
            }
            const teen = choice > 10 && choice < 20;
            const endsWithOne = choice % 10 === 1;
            if (choicesLength < 4) {
                return (!teen && endsWithOne) ? 1 : 2;
            }
            if (!teen && endsWithOne) {
                return 1;
            }
            if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
                return 2;
            }
            return (choicesLength < 4) ? 2 : 3;
        }
    }
});

const loadedLanguages = ['en', 'ru']; // our default language that is preloaded

function setI18nLanguage (lang) {
    i18n.locale = lang;
    axios.defaults.headers.common['Accept-Language'] = lang
    document.querySelector('html').setAttribute('lang', lang)
    return lang
}

export function loadLanguageAsync(lang) {
    // If the same language
    if (i18n.locale === lang) {
        return Promise.resolve(setI18nLanguage(lang))
    }

    // If the language was already loaded
    if (loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang))
    }

    // If the language hasn't been loaded yet
    return import(/* webpackChunkName: "lang-[request]" */ `@/lang/${lang}.js`).then(
        messages => {
            i18n.setLocaleMessage(lang, messages.default)
            loadedLanguages.push(lang)
            return setI18nLanguage(lang)
        }
    )
}