import Axios from '../axios'
import toastMixin from "./toast";

const FileAPI = {
    methods: {
        async saveFile(file) {
            let formData = new FormData();
            formData.append("file", file.file);
            let response = await Axios.post(process.env.VUE_APP_API_URL + `/api/v1/files/save`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.data.code === 0) {
                this.successToast(`File uploaded`);
            } else {
                this.errorToast(`Failed to upload file: ` + response.data.message);
            }

            return response.data;
        },

        getFileUrl(id) {
            return process.env.VUE_APP_API_URL + `/api/v1/files/${encodeURI(id)}`;
        },

        async deleteFile(id) {
            return await Axios.delete(process.env.VUE_APP_API_URL + `/api/v1/files/delete/${encodeURI(id)}`);
        }
    },
    mixins: [toastMixin]
};

export default FileAPI