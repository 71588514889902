export default {
    "log_in": "Log in",
    "login": "Login",
    "login_failed": "Invalid account or password",
    "password": "Password",
    "menu.dashboard": "Dashboard",
    "menu.users": "Users",
    "menu.users.list": "List Users",
    "menu.users.create": "Create User",
    "menu.roles": "Roles",
    "menu.roles.list": "List Roles",
    "menu.roles.create": "Create Role",
    "menu.solutions": "Solutions",
    "menu.solutions.list": "List Solutions",
    "menu.solutions.create": "Create Solution",
    "menu.admin": "Server Admin",
    "menu.admin.logs": "View Logs",

    "landing.title": "Solutions",

    "forms.email": "Email",
    "forms.name": "Name",
    "forms.display_name": "Display Name",
    "forms.position": "Position",
    "forms.biography": "Biography",
    "forms.password": "Password",
    "forms.passwords_do_not_match": "Passwords do not match",
    "forms.new_password": "New Password",
    "forms.confirm_password": "Confirm Password",
    "forms.permissions": "Permissions",
    "forms.at_least_chars": "At least {chars} characters",
    "forms.any.m": "Any",
    "forms.any.f": "Any",
    "forms.showAll": "Show all",
    "forms.select": "Select",
    "forms.selected": "Selected",
    "forms.selectType": "Select type",
    "forms.color": "Color",
    "forms.no": "No",
    "forms.solutionsSort": "Sort by",
    "forms.solutionsSort.time": "By time",
    "forms.solutionsSort.rating": "By rating",
    "forms.solutionsSort.similarity": "By similarity",
    "forms.expertsSort.comments": "By comments",
    "forms.save_changes": "Save changes",
    "forms.reset": "Reset",
    "forms.show": "Show",
    "forms.add": "Add",
    "forms.save": "Save",
    "forms.edit": "Edit",
    "forms.delete": "Delete",
    "forms.apply": "Apply",
    "forms.clear": "Clear",
    "forms.search.ph": "Enter search query",
    "forms.find": "Find",
    "forms.list": "List",
    "forms.map": "On map",
    "forms.send": "Send",
    "forms.cancel": "Cancel",
    "download": "Download",
    "expand": "Expand",
    "collapse": "Collapse",
    "showMore": "Show more",
    "showAll": "Show all",
    "similarSolutions": "Similar solutions",
    "similarTo": "Similar to",

    "nav.experts": "Experts",
    "nav.about": "About",
    "nav.admin": "Admin",
    "toMainPage": "Return to main page",
    "footer.institute": "© 2021 Design and urbanism institute of ITMO university",

    "experts.registered": "Registered",
    "experts.how.header": "How to become an expert?",
    "experts.how.text": "...",

    "stats.totalSolutions": "Total solutions",
    "stats.bestPractices": "Best practices",
    "stats.comments": "Comments",
    "stats.updated": "Last update",

    "user.edit": "Edit user",
    "user.change_password": "Change Password",
    "user.ban": "Ban User",
    "user.unban": "Unban User",
    "user.activate": "Activate User",
    "user.not_activated": "User not activated",
    "user.not_logged_in": "Please sign in first",
    "user.name.placeholder": "Enter your name",
    "user.password.placeholder": "Enter new password",
    "user.repeat_password.placeholder": "Repeat new password",
    "user.change_password.caption": "Change Password",
    "user.profile.caption": "Profile Settings",
    "user.addAvatar": "Add avatar",
    "user.changeAvatar": "Change avatar",

    "role.edit": "Editing Role",
    "role.name": "Role name",

    "solution.fullName": "Full name",
    "solution.fullName.description": "Full name of solution provided by its developer",
    "solution.shortName": "Short name",
    "solution.shortName.description": "Short name of solution",
    "solution.ideaAuthor": "Idea's author",

    "solution.solutionType": "Solution type",
    "solution.solutionType.BUSINESS_SOLUTION" : "Business solution",
    "solution.solutionType.BEST_PRACTICE" : "Best practice",
    "solution.solutionType.PROJECT" : "Project",
    "solution.solutionType.IDEA" : "Idea",

    "solution.implementations": "Implementations",
    "solution.implementations.description": "Information about the places of realization and those responsible persons. These can be municipalities or districts or cities",

    "solution.sphere": "Sphere",
    "solution.sphereType": "Sphere type",
    "solution.sphereType.description": "Global positioning of the solution",

    "solution.sphere.CITY_MANAGEMENT" : "City management",
    "solution.sphere.SERVICES_PROVISION" : "Services provision",
    "solution.sphere.TERRITORIAL_GROWTH" : "Territorial growth",

    "solution.cityManagementPurview": "Purview",
    "solution.cityManagementPurview.REGULATION": "Regulation",
    "solution.cityManagementPurview.ACTIVITY": "Activity",
    "solution.cityManagementPurview.TERRITORY_MANAGEMENT": "Territory management",
    "solution.cityManagementPurview.LOCAL_GOVERNMENT": "Local government",

    "solution.industry": "Industry",
    "solution.industry.description": "Industry",

    "solution.infrastructure": "Infrastructure",
    "solution.infrastructures": "Infrastructures",
    "solution.infrastructure.BASE": "Base",
    "solution.infrastructure.SOCIAL": "Social",
    "solution.infrastructure.TRANSPORT": "Transport",
    "solution.infrastructure.SERVICE": "Service",
    "solution.infrastructure.LEISURE": "Leisure",
    "solution.infrastructure.TOURIST": "Tourist",

    "solution.function": "Function",
    "solution.functions": "Functions",
    "solution.serviceTypes": "Service types",
    "solution.serviceTypes.description": "The list of service types",

    "solution.projectType": "Project type",
    "solution.territorialGrowth.LOCAL_TERRITORY_COMPLEX_DEVELOPMENT": "Local territory complex development",
    "solution.territorialGrowth.URBAN_TRANSFORMATION": "Urban transformation",
    "solution.territorialGrowth.IMPROVEMENT_OF_PUBLIC_SERVICES": "Improvement of public services",

    "solution.image": "Solution's avatar",

    "solution.description" : "Description",
    "solution.description.targets": "Targets",
    "solution.description.achievements": "Achievements",
    "solution.description.achievements.description": "Solution achievements",
    "solution.description.text": "Text",
    "solution.description.text.description": "Text with a description",
    "solution.description.scale": "Scale",
    "solution.description.images": "Images",

    "solution.description.scale.ON_SITE": "On site",
    "solution.description.scale.LOCAL": "Local",
    "solution.description.scale.DISTRICT": "District",
    "solution.description.scale.CITY": "City",
    "solution.description.scale.REGION": "Region",
    "solution.description.scale.FEDERAL": "Federal",
    "solution.description.scale.WORLDWIDE": "Worldwide",

    "solution.description.locationDescription": "Location description",
    "solution.description.locationDescriptionPart": "Location",
    "solution.description.locationDescriptionPart.placeholder": "Enter location",
    "solution.description.location": "Location",
    "solution.description.location.help": "Click map or enter coordinates manually and press the button",
    "solution.description.geometry": "Geometry",
    "solution.description.geometry.description": "Solution's geometry",
    "solution.description.geometry.help": "To change the shape of a polygon, first select the desired one, then click on the map on the right to add a point or click with Ctrl pressed to delete the last point of the polygon",
    "solution.description.files": "Files",

    "polygon.delete": "Delete polygon",

    "solution.expertOpinion": "Expert opinion",
    "solution.expertOpinion.rating": "Rating",
    "solution.expertOpinion.rating.description": "Solution's rating from 1 to 5",
    "solution.expertOpinion.text": "Text",
    "solution.expertOpinion.text.description": "Expert's opinion text",
    "solution.expertOpinion.comments": "Experts' comments",
    "solution.expertOpinion.comments.add": "Add your comment",
    "comments": "{n} comments",
    "experts": "{n} experts",

    "solution.segment": "Segment",
    "solution.segment.PEOPLE": "People",
    "solution.segment.BUSINESS": "Business",
    "solution.segment.GOVERNMENT": "Government",

    "solution.targetGroup": "Target group",
    "solution.situation": "Situation",
    "solution.indicators": "Target indicators",
    "solution.targetValues": "Target values",
    "solution.focusTarget": "Focus target",
    "solution.risks": "Risks",
    "solution.focusTargetRisk.comment": "Comment",

    "solution.characteristic.target": "Characteristic",
    "solution.characteristic.risk": "Characteristic",
    "solution.characteristic.PROVISION": "Provision",
    "solution.characteristic.QUALITY": "Quality",
    "solution.characteristic.VARIETY": "Variety",
    "solution.characteristic.AVAILABILITY": "Availability",

    "realization": "Realization",
    "realizations": "Realizations",
    "realization.producer": "Producer",
    "realization.producer.description": "Name, link or contacts",
    "realization.cost": "Cost",
    "realization.cost.description": "Optional",

    "realization.sourceLink": "Source link",
    "realization.sourceLink.description": "Source link",
    "realization.sourceLink.BUSINESS_SOLUTION": "Source link",
    "realization.sourceLink.BEST_PRACTICE": "Source link",
    "realization.sourceLink.PROJECT": "Source project",
    "realization.sourceLink.PROJECT.description": "Project's source (link to the site or other sources)",
    "realization.sourceLink.IDEA": "Idea source",
    "realization.sourceLink.IDEA.description": "Source link. Can be article",
    "siteLink": "Link",

    "realization.practiceAffiliation": "Practice affiliation",
    "realization.practiceAffiliation.description": "Link to solution with type «best practice», linked with this solution",

    "solution.toast.saved": "Solution successfully saved",
    "solution.toast.error": "Failed to submit solution",
    "solution.toast.error.fullName": "Solution with such full name already exist",
    "solution.toast.error.shortName": "Solution with such short name already exist",

    "toast.success.title": "Success",
    "toast.error.title": "Error",

    "errors.not_found": "Page Not Found",
    "errors.not_found.text": "It looks like you found a glitch in the matrix...",

    "errors.forbidden": "Forbidden",
    "errors.forbidden.text": "It looks like you do not have access to this content"
}