<template>
    <div v-b-tooltip.hover.v-secondary :title="$t('solution.solutionType.' + st)">
        <div style="position: absolute; top: 0px; left: 0px; height: 40px; width: 36px">
            <b-img :src="require('../assets/icons/' + st + '.svg')" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"></b-img>
        </div>
        <div style="width: 36px; height: 40px" :style="'background-color: ' + getColorTheme(st).clr"></div>
        <div :style="'background-color: transparent; width: 0px; height: 0px;  border-top: 14px solid ' + getColorTheme(st).clr + '; border-right: 18px solid transparent'"></div>
        <div :style="'background-color: transparent; position: absolute; top: 40px; left: 18px; width: 0px; height: 0px;  border-top: 14px solid ' + getColorTheme(st).clr + '; border-left: 18px solid transparent'"></div>
    </div>
</template>
<script>
    export default {
        name: "SolutionTypeFlag",
        props: ["st"],
        methods: {
            getColorTheme(solutionType) {
                switch (solutionType) {
                    case 'BUSINESS_SOLUTION':
                        return { clr: '#7B61FF', icon: 'fas fa-fw fa-lg fa-briefcase'};
                    case 'BEST_PRACTICE':
                        return { clr: '#FF951A', icon: 'fas fa-fw fa-lg fa-star'};
                    case 'PROJECT':
                        return { clr: '#009DFF', icon: 'fas fa-fw fa-lg fa-drafting-compass'};
                    default:
                        return { clr: '#1B9E48', icon: 'fas fa-fw fa-lg fa-lightbulb'};
                }
            }
        }
    }
</script>