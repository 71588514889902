<template>
    <div>
        <div class="dark-stat">{{ statsValue }}</div>
        <div class="white-label">{{ description }}</div>
    </div>
</template>
<script>
    export default {
        name: "StatsCard",
        props: ["statsValue", "description"]
    }
</script>
<style scoped>
    .dark-stat {
        background: rgba(0, 0, 0, 0.5);
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        text-align: center;
        font-size: 24px;
        padding: 10px;
        color: #FFFFFF;
        min-width: 190px;
    }
    .white-label {
        margin-top: 10px;
        text-align: center;
        font-size: 14px;
        color: #FFFFFF;
    }
    @media screen and (max-width: 992px) {
        .dark-stat {
            min-width: 130px;
        }
    }
</style>