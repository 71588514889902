<template>
    <div style="padding-bottom: 60px">
        <NavBar></NavBar>
        <div class="oodi-div">
            <b-container>
                <div class="ml-0 mr-0 p-0 d-flex">
                    <div class="mb-1 w-100" style="padding: 0px 20px 0px 0px">
                        <b-input-group>
                            <b-form-input id="name-search" style="height: 48px; margin-left: 0px" size="lg" v-model="requestFilter.namePart" @keydown.enter.native="loadSolutions()"
                                          type="text" :placeholder="$t('forms.search.ph')" trim></b-form-input>
                            <b-input-group-append>
                                <b-button class="clear-search-btn" variant="light" style="width: 30px" @click="requestFilter.namePart = ''; loadSolutions()">
                                    <span>&times;</span>
                                </b-button>
                            </b-input-group-append>
                            <b-input-group-append>
                                <b-button class="find-button" type="button" variant="primary" v-on:click="loadSolutions()">
                                    {{ $t('forms.find') }}
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                    <div>
                        <b-button type="button" style="height: 48px" class="float-right" size="lg" variant="secondary" v-on:click="showFilterModal = !showFilterModal">
                            <b-img :src="require('../../assets/icons/sliders.svg')"></b-img>
                        </b-button>
                    </div>
                </div>
                <b-row id="stats" style="margin-top: 82px">
                    <StatsCard class="mr-auto" :statsValue="stats.totalSolutions" :description="$tc('stats.totalSolutions', stats.totalSolutions)"></StatsCard>
                    <StatsCard class="mx-auto" :statsValue="stats.bestPractices" :description="$tc('stats.bestPractices', stats.bestPractices)"></StatsCard>
                    <StatsCard class="mx-auto" :statsValue="stats.comments" :description="$tc('stats.comments', stats.comments)"></StatsCard>
                    <StatsCard class="ml-auto" v-if="stats.lastUpdate !== null" :statsValue="dateFormatted(stats.lastUpdate)" :description="$t('stats.updated')"></StatsCard>
                </b-row>
            </b-container>
        </div>
        <b-container class="p-0">
            <b-row class="m-0 p-0 mt-5">
                <b-col class="m-0 p-0 mb-1" md="6">
                    <b-button-group>
                        <b-button
                                :variant="showOnMap ? 'outline-primary' : 'primary'"
                                style="width: 115px; height:36px"
                                @click="showOnMap = false"
                        >
                            <b-img
                                    :src="require('../../assets/icons/grid_' + (showOnMap ? 'blue' : 'white') + '.svg')"
                                    style="margin-right: 7px"
                            ></b-img>
                            {{ $t('forms.list') }}
                        </b-button>
                        <b-button
                                :variant="showOnMap ? 'primary' : 'outline-primary'"
                                style="width: 115px; height:36px"
                                @click="showOnMap = true"
                        >
                            <b-img
                                    :src="require('../../assets/icons/map_' + (showOnMap ? 'white' : 'blue') + '.svg')"
                                    style="margin-right: 7px"
                            ></b-img>
                            {{ $t('forms.map') }}
                        </b-button>
                    </b-button-group>
                </b-col>
                <b-col lg="3" md="1" cols="12"></b-col>
                <b-col>
                    <b-dropdown v-if="!showOnMap" block no-caret right variant="light" toggle-class="dd">
                        <template #button-content>
                            <b-img :src="require('../../assets/icons/bars.svg')" class="mr-2"></b-img>
                            {{ requestFilter.orderBy == 'TIMESTAMP' ? $t("forms.solutionsSort.time") : requestFilter.orderBy == 'SIMILARITY' ? $t("forms.solutionsSort.similarity") : $t("forms.solutionsSort.rating") }}
                            <i class="fas fa-chevron-down fa-xs float-right mt-2"></i>
                        </template>
                        <b-dropdown-item v-if="requestFilter.similarToID != null" href="#" @click="requestFilter.orderBy = 'SIMILARITY'; requestFilter.page = 0; loadSolutions()">{{ $t("forms.solutionsSort.similarity") }}</b-dropdown-item>
                        <b-dropdown-item href="#" @click="requestFilter.orderBy = 'TIMESTAMP'; requestFilter.page = 0; loadSolutions()">{{ $t("forms.solutionsSort.time") }}</b-dropdown-item>
                        <b-dropdown-item href="#" @click="requestFilter.orderBy = 'RATING'; requestFilter.page = 0; loadSolutions()">{{ $t("forms.solutionsSort.rating") }}</b-dropdown-item>
                    </b-dropdown>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col>
                    <b-badge v-if="requestFilter.similarToID != null" class="filter-badge" pill variant="secondary">
                        {{ $t("similarTo") + ": " + similarToName }}
                        <b-link @click="requestFilter.similarToID = null; if (requestFilter.orderBy == 'SIMILARITY') requestFilter.orderBy = 'TIMESTAMP'; loadSolutions()">
                            <i class="fas fa-times ml-1"></i>
                        </b-link>
                    </b-badge>
                    <b-badge v-if="requestFilter.solutionType != null" class="filter-badge" pill variant="secondary">
                        {{ $t("solution.solutionType." + requestFilter.solutionType) }}
                        <b-link @click="requestFilter.solutionType = null; loadSolutions()">
                            <i class="fas fa-times ml-1"></i>
                        </b-link>
                    </b-badge>
                    <b-badge v-if="requestFilter.author != null" class="filter-badge" pill variant="secondary">
                        {{ "Автор идеи: " + requestFilter.author }}
                        <b-link @click="requestFilter.author = null; loadSolutions()">
                            <i class="fas fa-times ml-1"></i>
                        </b-link>
                    </b-badge>
                    <b-badge v-if="requestFilter.scale != null" class="filter-badge" pill variant="secondary">
                        {{ $t("solution.description.scale." + requestFilter.scale) }}
                        <b-link @click="requestFilter.scale = null; loadSolutions()">
                            <i class="fas fa-times ml-1"></i>
                        </b-link>
                    </b-badge>
                    <b-badge v-if="requestFilter.sphereType != null" class="filter-badge" pill variant="secondary">
                        {{ $t("solution.sphere." + requestFilter.sphereType) }}
                        <b-link @click="requestFilter.sphereType = null; loadSolutions()">
                            <i class="fas fa-times ml-1"></i>
                        </b-link>
                    </b-badge>
                    <b-badge v-if="requestFilter.purview != null" class="filter-badge" pill variant="secondary">
                        {{ $t("solution.cityManagementPurview." + requestFilter.purview) }}
                        <b-link @click="requestFilter.purview = null; loadSolutions()">
                            <i class="fas fa-times ml-1"></i>
                        </b-link>
                    </b-badge>
                    <b-badge v-if="requestFilter.industry != null" class="filter-badge" pill variant="secondary">
                        {{ $t("solution.industry") }}: {{ requestFilter.industry }}
                        <b-link @click="requestFilter.industry = null; loadSolutions()">
                            <i class="fas fa-times ml-1"></i>
                        </b-link>
                    </b-badge>
                    <b-badge v-if="requestFilter.infrastructure != null" class="filter-badge" pill variant="secondary">
                        {{ $t("solution.infrastructure." + requestFilter.infrastructure) }}
                        <b-link @click="requestFilter.infrastructure = null; loadSolutions()">
                            <i class="fas fa-times ml-1"></i>
                        </b-link>
                    </b-badge>
                    <b-badge v-if="requestFilter.infrastructureFunction != null" class="filter-badge" pill variant="secondary">
                        {{ $t("solution.function") }}: {{ requestFilter.infrastructureFunction }}
                        <b-link @click="requestFilter.infrastructureFunction = null; loadSolutions()">
                            <i class="fas fa-times ml-1"></i>
                        </b-link>
                    </b-badge>
                    <b-badge v-if="requestFilter.functionServiceType != null" class="filter-badge" pill variant="secondary">
                        {{ $t("solution.serviceType") }}: {{ requestFilter.functionServiceType }}
                        <b-link @click="requestFilter.functionServiceType = null; loadSolutions()">
                            <i class="fas fa-times ml-1"></i>
                        </b-link>
                    </b-badge>
                    <b-badge v-if="requestFilter.projectType != null" class="filter-badge" pill variant="secondary">
                        {{ $t("solution.territorialGrowth." + requestFilter.projectType) }}
                        <b-link @click="requestFilter.projectType = null; loadSolutions()">
                            <i class="fas fa-times ml-1"></i>
                        </b-link>
                    </b-badge>
                    <b-badge v-if="requestFilter.locationDescriptionPart != null" class="filter-badge" pill variant="secondary">
                        {{ $t("solution.description.locationDescriptionPart") + ": " + requestFilter.locationDescriptionPart }}
                        <b-link @click="requestFilter.locationDescriptionPart = null; loadSolutions()">
                            <i class="fas fa-times ml-1"></i>
                        </b-link>
                    </b-badge>
                </b-col>
            </b-row>
            <b-row v-if="showOnMap">
                <b-col>
                    <LeafletMap ref="onMap" style="height:460px" :markers="mapMarkers" :clusters="true"></LeafletMap>
                </b-col>
            </b-row>
            <b-row v-else class="mt-4" style="padding-left: 10px; margin-right: -20px">
                <b-col cols="12" sm="6" md="4" lg="3" class="mt-1 pl-0" style="padding-right: 20px; padding-bottom: 30px" v-for="solution in solutions" v-bind:key="solution.id">
                    <SolutionCard :solution="solution"></SolutionCard>
                </b-col>
            </b-row>
            <b-row class="m-0 p-0 mb-5 mt-3">
                <b-button
                        v-if="loading == false && totalSolutions > requestFilter.page * requestFilter.size"
                        variant="primary"
                        class="ml-auto mr-auto"
                        style="padding: 10px 40px 10px 40px"
                        @click="loadSolutions(true)"
                >
                    {{ $t('showMore') }}
                </b-button>
            </b-row>
        </b-container>
        <b-modal
                v-model="showFilterModal"
                size="lg"
                hide-header
                centered
                @ok="modalFiltersOK"
                @hidden="modalFilterClear"
                @shown="modalFilterShown"
        >
            <b-form-group label-cols-lg="3" label-cols-md="5" label-cols="12" :label="$t('solution.description.locationDescriptionPart')" label-for="location-part">
                <b-input-group>
                    <b-form-input id="location-part" size="lg" v-model="modalFilter.locationDescriptionPart" :placeholder="$t('solution.description.locationDescriptionPart.placeholder')"></b-form-input>
                    <b-input-group-append is-text>
                        <i class="fa fa-sm fa-fw fa-map-marker-alt"></i>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
            <b-form-group label-cols-lg="3" label-cols-md="5" label-cols="12" :label="$t('solution.solutionType')" label-for="type-filter">
                <b-form-select id="type-filter" size="lg" v-model="modalFilter.solutionType" :options="typeFilterOptions"></b-form-select>
            </b-form-group>
            <b-form-group label-cols-lg="3" label-cols-md="5" label-cols="12" :label="$t('solution.description.scale')" label-for="scale-filter">
                <b-form-select id="scale-filter" size="lg" v-model="modalFilter.scale" :options="scaleFilterOptions"></b-form-select>
            </b-form-group>
            <b-form-group label-cols-lg="3" label-cols-md="5" label-cols="12" :label="$t('solution.sphereType')" label-for="sphere-filter">
                <b-form-select id="sphere-filter" size="lg" v-model="modalFilter.sphereType" :options="sphereFilterOptions"></b-form-select>
            </b-form-group>
            <b-form-group label-cols-lg="3" label-cols-md="5" label-cols="12" v-if="this.modalFilter.sphereType == 'CITY_MANAGEMENT'" :label="$t('solution.cityManagementPurview')" label-for="purview-filter">
                <b-form-select id="purview-filter" size="lg" v-model="modalFilter.purview" :options="purviewFilterOptions"></b-form-select>
            </b-form-group>
            <b-form-group label-cols-lg="3" label-cols-md="5" label-cols="12" v-if="modalFilter.sphereType == 'CITY_MANAGEMENT'"  :label="$t('solution.industry')" label-for="industry-filter">
                <b-form-select id="industry-filter" size="lg" v-model="modalFilter.industry" :options="autocompleteIndustryValues">
                    <template #first>
                        <b-form-select-option :value="null">{{ $t('forms.any.f') }}</b-form-select-option>
                    </template>
                </b-form-select>
            </b-form-group>
            <b-form-group label-cols-lg="3" label-cols-md="5" label-cols="12" v-if="this.modalFilter.sphereType == 'SERVICES_PROVISION'" :label="$t('solution.infrastructure')" label-for="infrastructure-filter">
                <b-form-select id="infrastructure-filter" size="lg" v-model="modalFilter.infrastructure" :options="autocompleteInfrastructureValues">
                    <template #first>
                        <b-form-select-option :value="null">{{ $t('forms.any.f') }}</b-form-select-option>
                    </template>
                </b-form-select>
            </b-form-group>
            <b-form-group label-cols-lg="3" label-cols-md="5" label-cols="12" label-size="sm" v-if="this.modalFilter.sphereType == 'SERVICES_PROVISION'"  :label="$t('solution.function')" label-for="infrastructure-function-filter">
                <b-form-select id="infrastructure-function-filter" size="lg" v-model="modalFilter.infrastructureFunction" :options="autocompleteInfrastructureFunctionValues">
                    <template #first>
                        <b-form-select-option :value="null">{{ $t('forms.any.f') }}</b-form-select-option>
                    </template>
                </b-form-select>
            </b-form-group>
            <b-form-group label-cols-lg="3" label-cols-md="5" label-cols="12" v-if="this.modalFilter.sphereType == 'SERVICES_PROVISION'"  :label="$t('solution.serviceTypes')" label-for="function-st-filter">
                <b-form-select id="function-st-filter" size="lg" v-model="modalFilter.functionServiceType" :options="autocompleteFunctionServiceTypeValues">
                    <template #first>
                        <b-form-select-option :value="null">{{ $t('forms.any.f') }}</b-form-select-option>
                    </template>
                </b-form-select>
            </b-form-group>
            <b-form-group label-cols-lg="3" label-cols-md="5" label-cols="12" v-if="this.modalFilter.sphereType == 'TERRITORIAL_GROWTH'" :label="$t('solution.projectType')" label-for="project-filter">
                <b-form-select id="project-filter" size="lg" v-model="modalFilter.projectType" :options="projectTypeFilterOptions"></b-form-select>
            </b-form-group>
            <template #modal-footer="{ ok }">
                <b-button variant="primary" @click="ok()" class="ml-auto mr-1">
                    {{ $t('forms.apply') }}
                </b-button>
                <b-button variant="secondary" @click="modalFilterClear()" class="mr-auto">
                    {{ $t('forms.clear') }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import SolutionAPI from "../../mixins/SolutionAPI";
    import NavBar from "../../components/NavBar";
    import SolutionCard from "../../components/SolutionCard";
    import StatsCard from "../../components/StatsCard";
    import LeafletMap from "../../components/LeafletMap";
    import storage from '@/storage';

    export default {
        name: "Solutions",
        mixins: [SolutionAPI],
        components: {
            NavBar, SolutionCard, StatsCard, LeafletMap
        },
        methods: {
            async loadSolutions(appendSolutions) {
                this.loading = true;
                if (appendSolutions == undefined || appendSolutions == false) {
                    this.solutions = [];
                    this.requestFilter.page = 0;
                }
                let a = await this.find(this.requestFilter);
                if (this.requestFilter.similarToID != null) {
                    let findOne = await this.getSolution(this.requestFilter.similarToID);
                    if (findOne.data.code == 0) {
                        this.similarToName = findOne.data.data.shortName;
                    }
                }
                this.solutions = this.solutions.concat(a.data.objects.map(s => {
                        let solution = {};
                        solution.id = s.id;
                        solution.shortName = s.shortName;
                        solution.image = s.image;
                        solution.timestamp = new Date(s.timestamp);
                        solution.solutionType = s.solutionType;
                        solution.locationDescription = s.description.locationDescription;
                        solution.rating = s.expertOpinion == null ? 0.0 : s.expertOpinion.rating;
                        solution.commentsCount = s.expertOpinion == null ? 0 : s.expertOpinion.commentsCount;
                        return solution;
                    }
                ));
                this.mapMarkers = a.data.objects.filter(s => {
                        return s.description.locations.length > 0
                    }
                ).map(s => {
                        let marker = {};
                        marker.sID = s.id;
                        marker.sImage = s.image;
                        marker.sShortName = s.shortName;
                        marker.sLocationDescription = s.description.locationDescription;
                        marker.sRating = s.expertOpinion == null ? 0.0 : s.expertOpinion.rating;
                        marker.sCommentsCount = s.expertOpinion == null ? 0 : s.expertOpinion.commentsCount;
                        marker.lat = s.description.locations[0].lat;
                        marker.lon = s.description.locations[0].lon;
                        return marker;
                    }
                );
                if (this.$refs.onMap != undefined) {
                    this.$refs.onMap.updateBounds(this.mapMarkers);
                }
                this.loading = false;
                this.countSolutions();
                this.requestFilter.page++;
            },
            async countSolutions() {
                let a = await this.count(this.requestFilter);
                if (a.data.code == 0) {
                    this.totalSolutions = a.data.number;
                }
            },
            modalFiltersOK() {
                if (this.requestFilter.orderBy == 'SIMILARITY') this.requestFilter.orderBy = 'TIMESTAMP';
                this.requestFilter.solutionType = this.modalFilter.solutionType;
                this.requestFilter.scale = this.modalFilter.scale;
                this.requestFilter.sphereType = this.modalFilter.sphereType;
                this.requestFilter.purview = this.modalFilter.purview;
                this.requestFilter.industry = this.modalFilter.industry;
                this.requestFilter.infrastructure = this.modalFilter.infrastructure;
                this.requestFilter.infrastructureFunction = this.modalFilter.infrastructureFunction;
                this.requestFilter.functionServiceType = this.modalFilter.functionServiceType;
                this.requestFilter.projectType = this.modalFilter.projectType;
                this.requestFilter.locationDescriptionPart = this.modalFilter.locationDescriptionPart;
                this.loadSolutions();
            },
            modalFilterShown() {
                this.modalFilter = {
                    solutionType: this.requestFilter.solutionType,
                    scale: this.requestFilter.scale,
                    sphereType: this.requestFilter.sphereType,
                    purview: this.requestFilter.purview,
                    industry: this.requestFilter.industry,
                    infrastructure: this.requestFilter.infrastructure,
                    infrastructureFunction: this.requestFilter.infrastructureFunction,
                    functionServiceType: this.requestFilter.functionServiceType,
                    projectType: this.requestFilter.projectType,
                    locationDescriptionPart: this.requestFilter.locationDescriptionPart
                };
            },
            modalFilterClear() {
                this.modalFilter = {
                    solutionType: null,
                    scale: null,
                    sphereType: null,
                    purview: null,
                    industry: null,
                    infrastructure: null,
                    infrastructureFunction: null,
                    functionServiceType: null,
                    projectType: null,
                    locationDescriptionPart: null
                };
            },
            async loadStats() {
                let s = await this.getStats();
                this.stats = {
                    totalSolutions: s.data.data.totalSolutions,
                    bestPractices: s.data.data.bestPractices,
                    comments: s.data.data.comments,
                    lastUpdate: s.data.data.lastUpdate
                };
            },
            dateFormatted(d) {
                if (d == null) return null;
                return new Date(d).toLocaleDateString(storage.getters.getLocale);
            },
            async loadAutocompleteValues() {
                let a = await this.getAutocompleteValues('INDUSTRY');
                if (a.data.code == 0) {
                    this.autocompleteIndustryValues = a.data.objects.filter(function(av) { return av != null });
                }
                a = await this.getAutocompleteValues('INFRASTRUCTURE');
                if (a.data.code == 0) {
                    this.autocompleteInfrastructureValues = a.data.objects.filter(function(av) { return av != null });
                }
                a = await this.getAutocompleteValues('INFRASTRUCTURE_FUNCTION');
                if (a.data.code == 0) {
                    this.autocompleteInfrastructureFunctionValues = a.data.objects.filter(function(av) { return av != null });
                }
                a = await this.getAutocompleteValues('FUNCTION_SERVICE_TYPE');
                if (a.data.code == 0) {
                    this.autocompleteFunctionServiceTypeValues = a.data.objects.filter(function(av) { return av != null });
                }
            }
        },
        data() {
            return {
                loading: true,
                totalSolutions: 0,
                solutions: [],
                stats: {
                    totalSolutions: 0,
                    bestPractices: 0,
                    comments: 0,
                    lastUpdate: null
                },
                typeFilter: null,
                sphereFilter: null,
                requestFilter: {
                    similarToID: null,
                    solutionType: null,
                    sphereType: null,
                    author: null,
                    shortName: null,
                    namePart: null,
                    scale: null,
                    purview: null,
                    industry: null,
                    infrastructure: null,
                    infrastructureFunction: null,
                    functionServiceType: null,
                    projectType: null,
                    locationDescriptionPart: null,
                    orderBy: 'TIMESTAMP',
                    page: 0,
                    size: 12
                },
                modalFilter: {
                    scale: null,
                    sphereType: null,
                    purview: null,
                    industry: null,
                    infrastructure: null,
                    infrastructureFunction: null,
                    functionServiceType: null,
                    projectType: null,
                    locationDescriptionPart: null
                },
                sortOptions: [
                    {value: "TIMESTAMP", text: this.$t("forms.solutionsSort.time")},
                    {value: "RATING", text: this.$t("forms.solutionsSort.rating")}
                ],
                typeFilterOptions: [
                    {value: null, text: this.$t("forms.showAll")},
                    {value: "BUSINESS_SOLUTION", text: this.$t("solution.solutionType.BUSINESS_SOLUTION")},
                    {value: "BEST_PRACTICE", text: this.$t("solution.solutionType.BEST_PRACTICE")},
                    {value: "PROJECT", text: this.$t("solution.solutionType.PROJECT")},
                    {value: "IDEA", text: this.$t("solution.solutionType.IDEA")}
                ],
                sphereFilterOptions: [
                    {value: null, text: this.$t("forms.showAll")},
                    {value: "CITY_MANAGEMENT", text: this.$t("solution.sphere.CITY_MANAGEMENT")},
                    {value: "SERVICES_PROVISION", text: this.$t("solution.sphere.SERVICES_PROVISION")},
                    {value: "TERRITORIAL_GROWTH", text: this.$t("solution.sphere.TERRITORIAL_GROWTH")}
                ],
                scaleFilterOptions: [
                    {value: null, text: this.$t("forms.showAll")},
                    {value: "ON_SITE", text: this.$t("solution.description.scale.ON_SITE")},
                    {value: "LOCAL", text: this.$t("solution.description.scale.LOCAL")},
                    {value: "DISTRICT", text: this.$t("solution.description.scale.DISTRICT")},
                    {value: "CITY", text: this.$t("solution.description.scale.CITY")},
                    {value: "REGION", text: this.$t("solution.description.scale.REGION")},
                    {value: "FEDERAL", text: this.$t("solution.description.scale.FEDERAL")},
                    {value: "WORLDWIDE", text: this.$t("solution.description.scale.WORLDWIDE")}
                ],
                purviewFilterOptions: [
                    {value: null, text: this.$t("forms.showAll")},
                    {value: "REGULATION", text: this.$t("solution.cityManagementPurview.REGULATION")},
                    {value: "ACTIVITY", text: this.$t("solution.cityManagementPurview.ACTIVITY")},
                    {value: "TERRITORY_MANAGEMENT", text: this.$t("solution.cityManagementPurview.TERRITORY_MANAGEMENT")},
                    {value: "LOCAL_GOVERNMENT", text: this.$t("solution.cityManagementPurview.LOCAL_GOVERNMENT")}
                ],
                projectTypeFilterOptions: [
                    {value: null, text: this.$t("forms.showAll")},
                    {value: "LOCAL_TERRITORY_COMPLEX_DEVELOPMENT", text: this.$t("solution.territorialGrowth.LOCAL_TERRITORY_COMPLEX_DEVELOPMENT")},
                    {value: "URBAN_TRANSFORMATION", text: this.$t("solution.territorialGrowth.URBAN_TRANSFORMATION")},
                    {value: "IMPROVEMENT_OF_PUBLIC_SERVICES", text: this.$t("solution.territorialGrowth.IMPROVEMENT_OF_PUBLIC_SERVICES")}
                ],
                showFilterModal: false,
                autocompleteIndustryValues: [],
                autocompleteInfrastructureValues: [],
                autocompleteInfrastructureFunctionValues: [],
                autocompleteFunctionServiceTypeValues: [],
                showOnMap: false,
                mapMarkers: [],
                similarToName: ''
            }
        },
        mounted() {
            if (this.$route.query.similarTo != null) {
                this.requestFilter.similarToID = this.$route.query.similarTo;
                this.requestFilter.orderBy = 'SIMILARITY';
            }
            this.loadSolutions();
            this.loadStats();
            this.loadAutocompleteValues();
        }
    }
</script>
<style scoped>
.oodi-div {
    height: 352px;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%), url('~@/assets/SCM_background.jpg');
    box-shadow: inset 0px -1px 3px rgba(0, 0, 0, 0.1);
    background-repeat: no-repeat;
    background-position: 18% center;
    background-size: cover;
    margin-left: 0px;
    margin-right: 0px;
}

.oodi-div > .container {
    padding-top: 110px;
    padding-left: 38px;
    padding-right: 38px;
    height: 242px;

    display: flex;
    flex-direction: column;
}

.find-button {
    width: 121px;
}

.form-control-lg::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #C4C4C4;
}

.modal-body .form-control-lg::placeholder {
    font-size: 14px;
}

#stats {
    margin-top: auto;
    margin-bottom: 24px;
    margin-left: 0px;
    margin-right: 0px;
}

@media only screen and (max-width: 992px) {
    .find-button {
        width: 90px;
    }
    #stats {
        margin-left: 0;
        margin-right: 0;
    }
}

@media only screen and (max-width: 767px) {
    #stats {
        display: none;
    }
}

.btn, .btn-group, .b-dropdown, .input-group, .custom-select, >>>.form-control {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
}

.input-group >>>.form-control {
    box-shadow: none;
}

.input-group-text {
    color: #808080;
    background-color: #fff;
    border: none;
}

#name-search:focus {
    box-shadow: none;
}

.btn-group > .btn, .clear-search-btn {
    box-shadow: none;
}

.clear-search-btn > span {
    color: #808080;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
}

>>> .dd {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: #5A6A7F;
}

>>> .modal-footer {
    border-top: none;
    padding: 0;
    margin-top: 15px;
}

>>> .modal-footer > .btn {
    margin: 0;
    padding: 10px 40px 10px 40px;
}

>>> .modal-content {
    padding: 30px;
}

>>> .modal-backdrop {
    opacity: 0.8;
}

>>> .modal-body {
    padding: 0px;
}

>>> .modal-body > .form-group > label {
    color: #000;
    padding: 10px 0px 10px 0px;
    font-size: 14px;
}

>>> .modal-body > .form-group > .col > .custom-select, >>> .form-control {
    color: #000;
    font-weight: 400;
    margin-left: 0px;
}

>>> .col-form-label {
  font-family: 'Roboto', sans-serif;
}

>>> .custom-select {
  background: #fff;
  font-family: 'Roboto', sans-serif;
  padding-top: 9px;
}

>>> .btn {
  font-family: 'Roboto', sans-serif;
}

>>> .modal-body > .form-row {
    margin-left: 0px;
    margin-right: 0px;
}

>>> .modal-body > .form-row > .col {
    padding-left: 10px;
}

>>> .modal-body > .form-row > label {
    padding-left: 10px;
}

@media only screen and (max-width: 767px) {
    >>> .modal-content {
        padding: 5px;
    }
}

.filter-badge {
    font-size: 12px;
    font-weight: normal;
    padding: 6px 12px 6px 12px;
    margin-right: 5px;
}
.filter-badge > a {
    color: #fff;
}
</style>