<template>
    <b-container class="p-0 pt-1">
    <NavBar></NavBar>
    <b-skeleton-wrapper :loading="loading">
        <template #loading>
            <b-row class="p-3">
                <b-col md="5">
                    <b-row v-if="userCanEdit()" class="mb-2">
                        <b-col><b-skeleton type="button"></b-skeleton></b-col>
                    </b-row>
                    <b-row class="mb-2">
                        <b-col>
                            <b-skeleton width="100%"></b-skeleton>
                            <b-skeleton width="75%"></b-skeleton>
                            <b-skeleton-img width="200px" height="200px" no-aspect></b-skeleton-img>
                        </b-col>
                    </b-row>
                    <b-row class="mb-2">
                        <b-col v-for="n in 3" v-bind:key="n" >
                            <b-skeleton-img width="100px" height="100px" no-aspect>{{ n }}</b-skeleton-img>
                        </b-col>
                    </b-row>
                    <b-row class="mb-2">
                        <b-col>
                            <b-skeleton-img height="300px" no-aspect></b-skeleton-img>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-skeleton width="25%"></b-skeleton>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col>
                    <b-row v-for="n in 20" v-bind:key="n">
                        <b-skeleton class="mb-3" :width="(10 * (n % 4)) + (50 - (n * 2)) + '%'"></b-skeleton>
                    </b-row>
                </b-col>
            </b-row>
        </template>
    </b-skeleton-wrapper>
    <b-row v-if="!loading" style="padding-bottom: 60px">
        <b-col md="6">
            <b-card>
                <b-row class="p-0">
                    <b-col>
                        <b-button v-if="userCanEdit()" type="button" class="float-left mr-2 mt-1" size="sm" variant="outline-secondary" v-on:click="editClicked()">
                            <i class="fas fa-fw fa-xs fa-pen"></i>
                        </b-button>
                        <h1>
                            {{ solution.shortName }}
                        </h1>
                        <p class="sub-header m-0 mb-3">{{ solution.fullName }}</p>
                        <b-row class="p-0 m-0">
                            <b-img fluid v-if="solution.image != null" :src="getImageUrl(solution.image)" class="mw-100" style="margin-top: 24px"></b-img>
                        </b-row>
                        <b-row v-if="solution.description.images.length > 0" class="p-0" style="margin-top: 24px">
                            <b-col lg="3" md="4" cols="6" v-for="(imageURL, i) in imagesUrls" v-bind:key="i">
                                <a @click="imageIndex = i">
                                    <b-img fluid v-bind:src="imageURL"></b-img>
                                </a>
                            </b-col>
                            <vue-gallery-slideshow :images="imagesUrls" :index="imageIndex" @close="imageIndex = null"></vue-gallery-slideshow>
                        </b-row>
                    </b-col>
                </b-row>
            </b-card>
            <b-card>
                <b-row class="mb-5">
                    <b-col cols="8">
                        <h2>{{ $t('solution.expertOpinion') }}</h2>
                        <p style="font-size: 16px">{{ solution.expertOpinion.text }}</p>
                    </b-col>
                    <b-col>
                        <Rating class="float-right" :rating="solution.expertOpinion.rating"></Rating>
                    </b-col>
                </b-row>
                <h2 class="mb-5" v-if="userCanComment() || commentsTotal > 0">{{ $t('solution.expertOpinion.comments') }}
                    <span class="float-right sub-header mt-1">{{ $tc('comments', commentsTotal) }}</span>
                </h2>
                <b-table
                    v-show="commentsTotal > 0"
                    ref="commentsTable"
                    :items="commentsProvider"
                    thead-class="hidden_header"
                    :fields="[ { key: 'author', label: 'Автор'} ]"
                    :perPage="!commentsExpanded ? 3 : commentsPerPage"
                    :currentPage="commentsCurrentPage"
                    small
                    responsive
                >
                    <template #cell(author)="data">
                        <b-row class="m-0">
                            <b-avatar v-if="data.value.avatar != null" class="m-0 mr-3 mb-1" size="3rem" :src="getImageUrl(data.value.avatar)"></b-avatar>
                            <b-col class="p-0">
                                <p class="m-0">{{ data.value.name }}</p>
                                <p class="m-0 sub-header" v-if="data.value.position != null">{{ data.value.position }}</p>
                            </b-col>
                            <b-col class="p-0">
                                <div class="float-right">
                                    <b-button
                                            v-if="data.value.email == currentUserEmail()"
                                            size="sm"
                                            squared
                                            class="mt-1 mr-1"
                                            style="width:32px; height:32px;"
                                            variant="outline-secondary"
                                            v-b-tooltip.hover
                                            :title = "$t('forms.edit')"
                                            @click="editComment(data.item.id, data.item.text)"
                                    >
                                        <i class="fa fa-pen" aria-hidden="true"></i>
                                    </b-button>
                                    <b-button
                                            v-if="canDeleteComment(data.value.email == currentUserEmail())"
                                            size="sm"
                                            squared
                                            class="mt-1"
                                            style="width:32px; height:32px;"
                                            variant="outline-secondary"
                                            v-b-tooltip.hover
                                            :title="$t('forms.delete')"
                                            @click="deleteComment(data.item.id)"
                                    >
                                        <i class="fa fa-times" aria-hidden="true"></i>
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row class="m-0 mt-2">
                            <p class="text-break">{{ data.item.text }}</p>
                        </b-row>
                    </template>
                </b-table>
                <b-collapse id="collapse-4" v-model="commentsExpanded" class="mt-2">
                    <b-pagination
                        v-if="commentsPerPage < commentsTotal"
                        v-model="commentsCurrentPage"
                        :total-rows="commentsTotal"
                        :per-page="commentsPerPage"
                        align="right"
                        size="sm"
                    ></b-pagination>
                </b-collapse>
                <b-button
                        v-if="userCanComment()"
                        type="button"
                        class="mb-1"
                        size="sm"
                        variant="outline-secondary"
                        v-on:click="toggleCommentModal()">
                    <i class="fa fa-plus" aria-hidden="true"></i> {{ $t('solution.expertOpinion.comments.add') }}
                </b-button>
                <b-row class="p-0 m-0">
                    <b-link
                            :class="commentsExpanded ? 'not-collapsed' : 'collapsed'"
                            v-if="commentsTotal > 3"
                            @click="commentsExpanded = !commentsExpanded"
                    >
                        <span class="when-open">{{ $t('collapse') }} <i class="fas fa-chevron-up fa-xs"></i></span>
                        <span class="when-closed">{{ $t('expand') }} <i class="fas fa-chevron-down fa-xs"></i></span>
                    </b-link>
                </b-row>
            </b-card>
            <b-card v-if="mapMarkers.length > 0 || solution.description.locationDescription != null">
                <h2>
                    {{ $t('solution.description.locationDescription') }}
                    <span class="float-right sub-header mt-1 mb-1">
                        {{ solution.description.locationDescription }}
                    </span>
                </h2>
                <LeafletMap v-if="mapMarkers.length > 0" ref="locationsMap" style="height:300px" :markers="mapMarkers"></LeafletMap>
            </b-card>
        </b-col>
        <b-col md="6">
            <b-card>
                <div style="position: absolute; top: 0; right: 24px;">
                    <SolutionTypeFlag :st="solution.solutionType"></SolutionTypeFlag>
                </div>
                <h2 class="mt-2">{{ $t('solution.solutionType.' + solution.solutionType) }}</h2>
                <b-table-simple v-if="!prExpanded && this.practiceRealizations.length > 3" class="mb-0">
                    <b-tr>
                        <b-th style="width: 170px">{{ $t('realizations') }}</b-th>
                        <b-td>
                            <div v-for="n in 3" v-bind:key="n">
                                <b-img
                                        :src="require('../../assets/icons/' + practiceRealizations[n - 1].solutionType + '_color.svg')"
                                        style="width: 16px; height: 16px; margin: 0 10px 2px 0"
                                ></b-img>
                                <b-link :to="'/solution/' + practiceRealizations[n - 1].id">{{ practiceRealizations[n - 1].shortName }}</b-link>
                            </div>
                        </b-td>
                    </b-tr>
                </b-table-simple>
                <b-collapse id="collapse-pr" v-model="prExpanded" class="mt-2" v-if="this.practiceRealizations.length > 3">
                    <b-table-simple class="mb-0">
                        <b-tr>
                            <b-th style="width: 170px">{{ $t('realizations') }}</b-th>
                            <b-td>
                                <div v-for="pr in this.practiceRealizations" v-bind:key="pr.id">
                                    <b-img
                                            :src="require('../../assets/icons/' + pr.solutionType + '_color.svg')"
                                            style="width: 16px; height: 16px; margin: 0 10px 2px 0"
                                    ></b-img>
                                    <b-link :to="'/solution/' + pr.id">{{ pr.shortName }}</b-link>
                                </div>
                            </b-td>
                        </b-tr>
                    </b-table-simple>
                </b-collapse>
                <b-link
                        :class="prExpanded ? 'not-collapsed' : 'collapsed'"
                        v-if="practiceRealizations.length > 3"
                        @click="prExpanded = !prExpanded"
                        style="margin-left: 182px"
                >
                    <span class="when-open">{{ $t('collapse') }} <i class="fas fa-chevron-up fa-xs"></i></span>
                    <span class="when-closed">{{ $t('showMore') }} <i class="fas fa-chevron-down fa-xs"></i></span>
                </b-link>
                <b-table-simple>
                    <b-tr v-if="solution.practiceRealizations.length > 0 && solution.practiceRealizations.length < 4">
                        <b-th style="width: 170px">{{ $t('realizations') }}</b-th>
                        <b-td>
                            <div v-for="pr in this.practiceRealizations" v-bind:key="pr.id">
                                <b-img
                                        :src="require('../../assets/icons/' + pr.solutionType + '_color.svg')"
                                        style="width: 16px; height: 16px; margin: 0 10px 2px 0"
                                ></b-img>
                                <b-link :to="'/solution/' + pr.id">{{ pr.shortName }}</b-link>
                            </div>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="solution.realization != null && solution.realization.practiceAffiliation.length > 0">
                        <b-th style="width: 170px">{{ $t('realization.practiceAffiliation') }}</b-th>
                        <b-td>
                            <div v-for="pa in this.practiceAffiliation" v-bind:key="pa.id">
                                <b-img
                                        :src="require('../../assets/icons/' + pa.solutionType + '_color.svg')"
                                        style="width: 16px; height: 16px; margin: 0 10px 2px 0"
                                ></b-img>
                                <b-link :to="'/solution/' + pa.id">{{ pa.shortName }}</b-link>
                            </div>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="solution.realization != null && solution.realization.producer !== null">
                        <b-th style="width: 170px">{{ $t('realization.producer') }}</b-th>
                        <b-td>
                            <div v-for="(part, index) in solution.realization.producer.split(' ')" v-bind:key="index">
                                <b-link
                                        v-if="part.startsWith('http')"
                                        :href="part"
                                        class="text-break"
                                >
                                    {{ part }}
                                </b-link>
                                <span v-else class="text-break">{{ part }}</span>
                            </div>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="solution.realization != null && solution.realization.cost !== null">
                        <b-th style="width: 170px">{{ $t('realization.cost') }}</b-th>
                        <b-td>{{ solution.realization.cost }}</b-td>
                    </b-tr>
                    <b-tr v-if="solution.realization != null && solution.realization.sourceLink !== null">
                        <b-th style="width: 170px">{{ $t('realization.sourceLink.' + solution.solutionType) }}</b-th>
                        <b-td>
                            <div v-for="(part, index) in solution.realization.sourceLink.split(' ')" v-bind:key="index">
                                <b-link
                                        v-if="part.startsWith('http')"
                                        :href="part"
                                        class="text-break"
                                >
                                    {{ part }}
                                </b-link>
                                <span v-else class="text-break">{{ part }}</span>
                            </div>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="solution.realization != null && solution.realization.implementations != null">
                        <b-th style="width: 170px">{{ $t('solution.implementations') }}</b-th>
                        <b-td>{{ solution.realization.implementations }}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-th style="width: 170px">{{ $t('solution.sphereType') }}</b-th>
                        <b-td>{{ $t('solution.sphere.' + solution.sphereDetails.sphereType) }}</b-td>
                    </b-tr>
                    <b-tr v-if="solution.sphereDetails.sphereType == 'CITY_MANAGEMENT'">
                        <b-th style="width: 170px">{{ $t('solution.cityManagementPurview') }}</b-th>
                        <b-td>{{ $t('solution.cityManagementPurview.' + solution.sphereDetails.cityManagementPurview) }}</b-td>
                    </b-tr>
                    <b-tr v-if="solution.sphereDetails.sphereType == 'CITY_MANAGEMENT' && solution.sphereDetails.industry != null">
                        <b-th style="width: 170px">{{ $t('solution.industry') }}</b-th>
                        <b-td>{{ solution.sphereDetails.industry }}</b-td>
                    </b-tr>
                    <b-tr v-if="infrastructures.length > 0">
                        <b-th style="width: 170px">{{ $t('solution.infrastructures') }}</b-th>
                        <b-td>
                            <li v-for="(inf, index) in infrastructures" v-bind:key="index">
                                {{ inf }}
                            </li>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="infrastructureFunctions.length > 0">
                        <b-th style="width: 170px">{{ $t('solution.functions') }}</b-th>
                        <b-td>
                            <li v-for="(func, index) in infrastructureFunctions" v-bind:key="index">
                                {{ func }}
                            </li>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="functionServiceTypes.length > 0">
                        <b-th style="width: 170px">{{ $t('solution.serviceTypes') }}</b-th>
                        <b-td>
                            <li v-for="(st, index) in functionServiceTypes" v-bind:key="index">
                                {{ st }}
                            </li>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="solution.sphereDetails.sphereType == 'TERRITORIAL_GROWTH' && solution.sphereDetails.projectType != null">
                        <b-th style="width: 170px">{{ $t('solution.projectType') }}</b-th>
                        <b-td>{{ $t('solution.territorialGrowth.' + solution.sphereDetails.projectType) }}</b-td>
                    </b-tr>
                </b-table-simple>
            </b-card>
            <b-card>
                    <h2>{{ $t('solution.description') }}</h2>
                    <div v-if="solution.description.targets != null && solution.description.targets.length > 0">
                        <h3>{{ $t('solution.description.targets') }}</h3>
                        <ul class="pl-0" style="list-style-type: none">
                            <li v-for="t in solution.description.targets" v-bind:key="t">{{ t }}</li>
                        </ul>
                    </div>
                    <div v-if="solution.description.text != null && solution.description.text.length > 0" class="mb-4">
                        <h3>{{ $t('solution.description.text') }}</h3>
                        <div v-if="solution.description.text.length > 500">
                            <b-collapse id="collapse-description-text">
                                <v-md-preview class="p-0" style="word-wrap: normal" :text="solution.description.text">
                                </v-md-preview>
                            </b-collapse>
                            <v-md-preview class="p-0 when-closed" style="word-wrap: normal" :text="getFirstP(solution.description.text, 500)">
                            </v-md-preview>
                            <b-link v-b-toggle.collapse-description-text variant="primary">
                                <span class="when-open">{{ $t('collapse') }} <i class="fas fa-chevron-up fa-xs"></i></span>
                                <span class="when-closed">{{ $t('expand') }} <i class="fas fa-chevron-down fa-xs"></i></span>
                            </b-link>
                        </div>
                        <v-md-preview v-else class="p-0" style="word-wrap: normal" :text="solution.description.text">
                        </v-md-preview>
                    </div>
                    <div class="mb-4">
                        <h3>{{ $t('solution.description.scale') }}</h3>
                        <p>{{ $t('solution.description.scale.' + solution.description.scale) }}</p>
                    </div>
                    <div class="mb-4" v-if="solution.description.geometry != null && solution.description.geometry.polygons.length > 0">
                        <h3>{{ $t('solution.description.geometry') }}</h3>
                        <p v-for="loc in solution.description.locations" v-bind:key="loc.id">
                            {{ formatCoordinates(loc) }}
                        </p>
                        <LeafletMap style="height:300px" :polygons="solution.description.geometry.polygons"></LeafletMap>
                    </div>
                    <div v-if="solution.description.files.length > 0" class="mb-4">
                        <h3>{{ $t('solution.description.files') }}</h3>
                            <b-row v-for="(file, index) in solution.description.files" v-bind:key="index">
                                <b-col class="text-truncate">
                                    <b-img :src="require('../../assets/icons/attached.svg')" class="mr-2"></b-img>
                                    <span v-b-tooltip.hover :title="file.substring(file.indexOf('/') + 1)">
                                        {{ file.substring(file.indexOf('/') + 1) }}
                                    </span>
                                </b-col>
                                <b-col class="col-auto"><b-link :href="getFileUrl(file)">{{ $t('download') }}</b-link></b-col>
                            </b-row>
                    </div>
                    <div v-if="solution.description.achievements !== null" class="mb-4">
                        <h3>{{ $t('solution.description.achievements') }}</h3>
                        <p>{{ solution.description.achievements }}</p>
                    </div>
            </b-card>
            <div v-if="solution.focusTargetsAndRisks.length > 0">
                <b-col class="p-0">
                    <b-card v-if="getFocusTargets().length > 0">
                        <h2>{{ $t('solution.focusTarget') }}</h2>
                        <div v-for="(ftr, index) in this.getFocusTargets()" v-bind:key="ftr.id">
                            <hr v-show="index != 0">
                            <b-table-simple>
                                <b-tr>
                                    <b-th width="170px">{{ $t('solution.segment') }}</b-th>
                                    <b-td>{{ $t('solution.segment.' + ftr.segment) }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th>{{ $t('solution.targetGroup') }}</b-th>
                                    <b-td>{{ ftr.targetGroup }}</b-td>
                                </b-tr>
                                <b-tr v-if="ftr.situations.length > 0">
                                    <b-th>{{ $t('solution.situation') }}</b-th>
                                    <b-td>
                                        <ul class="pl-3 mb-0">
                                            <li v-for="(situation, index) in ftr.situations" v-bind:key="index">
                                                {{ situation }}
                                            </li>
                                        </ul>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th>{{ $t('solution.characteristic.target') }}</b-th>
                                    <b-td>{{ $t('solution.characteristic.' + ftr.characteristic) }}</b-td>
                                </b-tr>
                                <b-tr v-if="ftr.indicators != null">
                                    <b-th>{{ $t('solution.indicators') }}</b-th>
                                    <b-td>{{ ftr.indicators }}</b-td>
                                </b-tr>
                                <b-tr v-if="ftr.targetValues != null">
                                    <b-th>{{ $t('solution.targetValues') }}</b-th>
                                    <b-td>{{ ftr.targetValues }}</b-td>
                                </b-tr>
                                <b-tr v-if="ftr.comment != null">
                                    <b-th>{{ $t('solution.focusTargetRisk.comment') }}</b-th>
                                    <b-td>{{ ftr.comment }}</b-td>
                                </b-tr>
                            </b-table-simple>
                        </div>
                    </b-card>
                    <b-card v-if="getRisks().length > 0">
                        <h2>{{ $t('solution.risks') }}</h2>
                        <div v-for="(risk, index) in this.getRisks()" v-bind:key="risk.id">
                            <hr v-show="index != 0">
                            <b-table-simple>
                                <b-tr>
                                    <b-th width="170px">{{ $t('solution.segment') }}</b-th>
                                    <b-td>{{ $t('solution.segment.' + risk.segment) }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th>{{ $t('solution.targetGroup') }}</b-th>
                                    <b-td>{{ risk.targetGroup }}</b-td>
                                </b-tr>
                                <b-tr v-if="risk.situations.length > 0">
                                    <b-th>{{ $t('solution.situation') }}</b-th>
                                    <b-td>
                                        <ul class="pl-3 mb-0">
                                            <li v-for="(situation, index) in risk.situations" v-bind:key="index">
                                                {{ situation }}
                                            </li>
                                        </ul>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th>{{ $t('solution.characteristic.risk') }}</b-th>
                                    <b-td>{{ $t('solution.characteristic.' + risk.characteristic) }}</b-td>
                                </b-tr>
                                <b-tr v-if="risk.indicators != null">
                                    <b-th>{{ $t('solution.indicators') }}</b-th>
                                    <b-td>{{ risk.indicators }}</b-td>
                                </b-tr>
                                <b-tr v-if="risk.targetValues != null">
                                    <b-th>{{ $t('solution.targetValues') }}</b-th>
                                    <b-td>{{ risk.targetValues }}</b-td>
                                </b-tr>
                                <b-tr v-if="risk.comment != null">
                                    <b-th>{{ $t('solution.focusTargetRisk.comment') }}</b-th>
                                    <b-td>{{ risk.comment }}</b-td>
                                </b-tr>
                            </b-table-simple>
                        </div>
                    </b-card>
                </b-col>
            </div>
        </b-col>
    </b-row>
    <b-row v-if="!loading && similarSolutions.length > 0" style="padding-bottom: 60px">
        <b-col>
            <b-row class="m-0 mb-3">
                <b-col class="m-0 p-0">
                    <h1>
                        {{ $t('similarSolutions') }}
                        <b-link :href="'/#/?similarTo=' + solution.id" class="float-right small-link mt-2">
                            {{ $t('showAll') }}...
                        </b-link>
                    </h1>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" sm="6" md="4" lg="3" style="padding-bottom: 30px" v-for="solution in similarSolutions" v-bind:key="solution.id">
                    <SolutionCard :solution="solution"></SolutionCard>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
    <b-modal
            v-model="showCommentModal"
            size="lg"
            centered
            @hidden="resetCommentModal"
            @ok="addComment"
    >
        <template #modal-header>
            {{ $t('solution.expertOpinion.comments.add') }}
        </template>
        <h3>{{ currentUserEmail() }}</h3>
        <b-form-textarea v-model="newComment" rows="5" max-rows="10"></b-form-textarea>
        <template #modal-footer="{ ok, cancel }">
            <b-button size="sm" variant="info" @click="ok()">
                {{ $t('forms.send') }}
            </b-button>
            <b-button size="sm" variant="danger" @click="cancel()">
                {{ $t('forms.cancel') }}
            </b-button>
        </template>
    </b-modal>
    </b-container>
</template>
<script>
    import NavBar from "../../components/NavBar";
    import SolutionCard from "../../components/SolutionCard";
    import SolutionTypeFlag from "../../components/SolutionTypeFlag";
    import Rating from "../../components/Rating";
    import ImageAPI from "../../mixins/ImageAPI";
    import FileAPI from "../../mixins/FileAPI";
    import SolutionAPI from "../../mixins/SolutionAPI";
    import LeafletMap from "../../components/LeafletMap";
    import storage from '../../storage';
    import DmsCoordinates from "dms-conversion";
    import VueGallerySlideshow from 'vue-gallery-slideshow';

    import VMdPreview from '@kangc/v-md-editor/lib/preview';
    import '@kangc/v-md-editor/lib/style/preview.css';
    import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
    import '@kangc/v-md-editor/lib/theme/style/vuepress.css';

    VMdPreview.use(vuepressTheme);

    export default {
        name: "SolutionPage",
        components: {
            NavBar,
            SolutionCard,
            SolutionTypeFlag,
            Rating,
            LeafletMap,
            VMdPreview,
            VueGallerySlideshow
        },
        props: ['id'],
        mixins: [ImageAPI, FileAPI, SolutionAPI],
        data() {
            return {
                loading: true,
                solution : {
                    description: {
                        images: [],
                        files: [],
                        locations: []
                    },
                    focusTargetsAndRisks: [],
                    sphereDetails: {},
                    expertOpinion: {
                        comments: []
                    }
                },
                mapMarkers: [],
                infrastructures: [],
                infrastructureFunctions: [],
                functionServiceTypes: [],
                practiceAffiliation: [],
                practiceRealizations: [],
                showCommentModal: false,
                newComment: "",
                commentsPage: [],
                commentsCurrentPage: 1,
                commentsPerPage: 5,
                commentsTotal: 0,
                alreadyCommented: false,
                editCommentID: null,
                commentsExpanded: false,
                prExpanded: false,
                imageIndex: null,
                imagesUrls: [],
                similarSolutions: []
            }
        },
        mounted() {
            this.loadSolution();
        },
        watch: {
            id: function (val) {
                this.loadSolution(val);
            }
        },
        methods: {
            async loadSolution(newID) {
                let s = await this.getSolution(newID == null ? this.id : newID);
                if (s.data.code == 0) {
                    this.solution = s.data.data;
                    this.mapMarkers = [];
                    if (this.solution.description.locations.length > 0) {
                        this.solution.description.locations.forEach(loc => {
                            this.mapMarkers.push({lat: loc.lat, lon: loc.lon});
                        });
                    }
                    this.infrastructures = [];
                    this.infrastructureFunctions = [];
                    this.functionServiceTypes = [];
                    if (this.solution.sphereDetails.functionServiceTypes != null) {
                        this.solution.sphereDetails.functionServiceTypes.forEach(st => {
                            if (!this.infrastructures.includes(st.infrastructureName)) {
                                this.infrastructures.push(st.infrastructureName);
                            }
                            if (!this.infrastructureFunctions.includes(st.functionName)) {
                                this.infrastructureFunctions.push(st.functionName);
                            }
                            this.functionServiceTypes.push(st.name);
                        });
                    }
                    this.practiceAffiliation = [];
                    if (this.solution.realization != null && this.solution.realization.practiceAffiliation.length > 0) {
                        this.solution.realization.practiceAffiliation.forEach(async pa => {
                            let paSol = (await this.getSolution(pa)).data;
                            if (paSol.code == 0) {
                                this.practiceAffiliation.push({
                                    id: paSol.data.id,
                                    shortName: paSol.data.shortName,
                                    rating: paSol.data.expertOpinion.rating,
                                    solutionType: paSol.data.solutionType
                                });
                            }
                        });
                    }
                    this.practiceRealizations = [];
                    if (this.solution.practiceRealizations.length > 0) {
                        this.solution.practiceRealizations.forEach(async pr => {
                            let prSol = (await this.getSolution(pr)).data;
                            if (prSol.code == 0) {
                                this.practiceRealizations.push({
                                    id: prSol.data.id,
                                    shortName: prSol.data.shortName,
                                    rating: prSol.data.expertOpinion.rating,
                                    solutionType: prSol.data.solutionType
                                });
                            }
                        });
                    }
                    let ftr = await this.fetchFTR(this.solution.id);
                    if (ftr.data.code == 0) {
                        this.solution.focusTargetsAndRisks = ftr.data.objects;
                    }
                    let commentsTotal = await this.countComments(this.solution.id);
                    if (commentsTotal.data.code == 0) {
                        this.commentsTotal = commentsTotal.data.number;
                    }
                    if (this.$store.getters.isAuthenticated) {
                        let userCommented = await this.userCommented(this.solution.id);
                        if (userCommented.data.code == 0 && userCommented.data.number > 0) {
                            this.alreadyCommented = true;
                        }
                    }
                    let fetchComments = await this.fetchComments(this.solution.id, 0, this.commentsPerPage);
                    if (fetchComments.data.code == 0) {
                        this.comments = fetchComments.data.objects;
                    }
                }
                this.imagesUrls = this.solution.description.images.map(this.getImageUrl);
                let findSimilar = await this.find({ similarToID: this.solution.id, orderBy: 'SIMILARITY', page: 0, size: 4 });
                if (findSimilar.data.code == 0) {
                    this.similarSolutions = findSimilar.data.objects.map(s => {
                            let solution = {};
                            solution.id = s.id;
                            solution.shortName = s.shortName;
                            solution.image = s.image;
                            solution.timestamp = new Date(s.timestamp);
                            solution.solutionType = s.solutionType;
                            solution.locationDescription = s.description.locationDescription;
                            solution.rating = s.expertOpinion == null ? 0.0 : s.expertOpinion.rating;
                            solution.commentsCount = s.expertOpinion == null ? 0 : s.expertOpinion.commentsCount;
                            return solution;
                        }
                    );
                }
                this.loading = false;
                if (this.$refs.locationsMap != undefined) {
                    this.$refs.locationsMap.updateBounds(this.mapMarkers);
                }
                this.prExpanded = false;
            },
            userCanEdit() {
                return this.$store.getters.hasAuthority('MANAGE_SOLUTIONS');
            },
            userCanComment() {
                if (this.$store.getters.isAuthenticated && this.alreadyCommented) {
                    return false;
                }
                return this.$store.getters.hasAuthority('COMMENT_SOLUTIONS');
            },
            canDeleteComment(can) {
                return can || this.$store.getters.hasAuthority('SERVER_ADMIN');
            },
            currentUserEmail() {
                return this.$store.getters.getEmail;
            },
            editClicked() {
                this.$router.push("/admin/edit_solution/" + this.solution.id);
            },
            zoomUpdated (zoom) {
                this.zoom = zoom;
            },
            centerUpdated (center) {
                this.center = center;
            },
            getFocusTargets() {
                if (this.solution.focusTargetsAndRisks.length > 0) {
                    return this.solution.focusTargetsAndRisks.filter(ft => {
                        return !ft.isRisk;
                    });
                }
                return [];
            },
            getRisks() {
                if (this.solution.focusTargetsAndRisks.length > 0) {
                    return this.solution.focusTargetsAndRisks.filter(ft => {
                        return ft.isRisk;
                    });
                }
                return [];
            },
            commentsProvider(ctx) {
                const promise = this.fetchComments(this.solution.id, ctx.currentPage - 1, ctx.perPage);
                return promise.then(data => {
                    return data.data.objects;
                })
            },
            toggleCommentModal() {
                this.showCommentModal = !this.showCommentModal;
            },
            resetCommentModal() {
                this.newComment = "";
            },
            async addComment(event) {
                if (this.newComment.length <= 0) {
                    event.preventDefault();
                } else {
                    if (this.editCommentID == null) {
                        let commentResult = await this.commentSolution(this.id, { text: this.newComment });
                        if (commentResult.data.code == 0) {
                            this.commentsTotal++;
                            this.alreadyCommented = true;
                        }
                    } else {
                        await this.editSolutionComment(this.editCommentID, { text: this.newComment });
                        this.editCommentID = null;
                    }
                    this.resetCommentModal()
                    this.$refs.commentsTable.refresh();
                }
            },
            editComment(commentID, commentText) {
                this.newComment = commentText;
                this.editCommentID = commentID;
                this.toggleCommentModal();
            },
            async deleteComment(commentID) {
                let result = await this.deleteSolutionComment(commentID);
                if (result.data.code == 0) {
                    this.commentsTotal--;
                    let userCommented = await this.userCommented(this.solution.id);
                    if (userCommented.data.number == 0) {
                        this.alreadyCommented = false;
                    }
                    this.$refs.commentsTable.refresh();
                }
            },
            dateFormatted(timestamp) {
                return (new Date(timestamp)).toLocaleString(storage.getters.getLocale);
            },
            getFirstP(text, size) {
                let lineBreakIdx = text.indexOf('\n');
                var firstRow = lineBreakIdx >= 0 ? text.substr(0, lineBreakIdx) : text;
                return firstRow.length > size ? firstRow.substr(0, size) + "..." : firstRow;
            },
            formatCoordinates(location) {
                const dmsCoords = new DmsCoordinates(location.lat, location.lon);
                var s = dmsCoords.toString();
                if (s.includes(".")) {
                    s = s.substr(0, s.indexOf(".")) + s.substr(s.indexOf("."), 3) + s.substr(s.indexOf('″'));
                    s = s.substr(0, s.lastIndexOf(".")) + s.substr(s.lastIndexOf("."), 3) + s.substr(s.lastIndexOf('″'));
                }
                s = s.replace("N", "с. ш.");
                s = s.replace("E", "в. д.");
                s = s.replace("S", "ю. ш.");
                s = s.replace("W", "з. д.");
                return s;
            }
        }
    }
</script>
<style scoped>
    .card {
        border-radius: 8px;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
        margin-bottom: 20px;
        padding-top: 20px;
        width: 100%;
    }

    .card > .card-body {
        padding: 0px 24px 24px;
    }

    p, ul, td {
        color: #000;
    }

    td, th {
        padding-top: 20px;
        font-size: 14px;
    }

    >>>.v-md-editor-preview p {
        color: #000;
        font-size: 14px;
    }

    table th {
        padding-left: 0px;
        vertical-align: top;
    }

    td {
        padding-right: 0px !important;
    }

    h1 {
        font-weight: 300;
        font-size: 32px;
        color: #000;
    }

    h2 {
        font-size: 20px;
        margin-bottom: 24px;
        color: #000;
    }

    h3, th {
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 14px;
    }

    .sub-header {
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
    }

    .comment-author {
        font-size: 14px;
        color: #808080;
    }

    >>> .hidden_header {
      display: none;
    }

    >>> .table td, .table th {
        border-top: none;
    }

    >>> .v-md-editor-preview {
      word-break: normal;
      word-wrap: normal;
    }

    .collapsed > .when-open, .not-collapsed > .when-closed {
        display: none;
    }

    a.collapsed:hover{
        text-decoration: none;
    }

    a.collapsed > span:hover{
        text-decoration: underline;
    }

    .collapsing {
        -webkit-transition: none;
        transition: none;
        display: none;
    }

    a:not(.card-link) {
        color: #074A74;
        text-decoration: underline;
    }

    a:not(.card-link):hover {
        color: #0087CC;
    }

    .small-link {
        color: #808080 !important;
        font-size: 16px;
        text-decoration: none !important;
    }

    .mw-px {
         max-width: 350px;
    }

    @media only screen and (max-width: 1200px) {
       .mw-px {
            max-width: 280px;
        }
    }

    @media only screen and (max-width: 992px) {
       .mw-px {
            max-width: 170px;
        }
    }
</style>