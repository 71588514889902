<template>
    <b-card>
        <b-row class="p-0 m-0">
            <b-avatar :src="getImageUrl(expert.avatar)" size="80px" style="margin: 0px 30px 20px 0px"></b-avatar>
            <b-col class="p-0 m-0">
                <b-row class="p-0 m-0">
                    <h1>{{ expert.name }}</h1>
                </b-row>
                <b-row class="p-0 m-0">
                    <h2>{{ expert.position }}</h2>
                </b-row>
            </b-col>
        </b-row>
        <b-row class="p-0 m-0">
            <p>{{ expert.biography }}</p>
        </b-row>
        <b-row class="p-0 m-0">
            <p class="comments">
                <b-img :src="require('../assets/icons/comments.svg')" class="mr-2"></b-img>
                {{ $tc('comments', expert.comments) }} на сайте
            </p>
        </b-row>
    </b-card>
</template>
<script>
    import ImageAPI from "../mixins/ImageAPI";

    export default {
        name: "SolutionCard",
        props: ["expert"],
        mixins: [ImageAPI]
    }
</script>
<style scoped>
    .card {
        min-width: 200px;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
    }

    .card-body {
        padding: 30px 30px 23px 30px;
    }

    h1 {
        font-size: 20px;
        line-height: 26px;
        color: #000;
    }

    h2 {
        margin-top: 10px;
        font-size: 14px;
        line-height: 16px;
        color: #808080;
    }

    p {
        font-size: 14px;
        line-height: 16px;
        color: #000;
    }

    p.comments {
        margin-bottom: 0px;
        font-size: 12px;
        line-height: 14px;
        color: #808080;
    }
</style>