export default {
    "log_in": "Войти",
    "login": "Логин",
    "password": "Пароль",
    "login_failed": "Неверное имя пользователя или пароль",
    "menu.dashboard": "Админка",
    "menu.users": "Пользователи",
    "menu.users.list": "Список пользователей",
    "menu.users.create": "Создать пользователя",
    "menu.roles": "Роли",
    "menu.roles.list": "Список ролей",
    "menu.roles.create": "Создать роль",
    "menu.solutions": "Решения",
    "menu.solutions.list": "Список решений",
    "menu.solutions.create": "Создать решение",
    "menu.admin": "Администрирование",
    "menu.admin.logs": "Смотреть логи",

    "landing.title": "Решения",

    "forms.email": "Email",
    "forms.name": "Имя",
    "forms.display_name": "Отображаемое имя",
    "forms.position": "Должность",
    "forms.biography": "Биография",
    "forms.password": "Пароль",
    "forms.passwords_do_not_match": "Пароли не совпадают",
    "forms.new_password": "Новый пароль",
    "forms.confirm_password": "Подтвердить пароль",
    "forms.permissions": "Разрешения",
    "forms.at_least_chars": "Как минимум {chars} символов",
    "forms.any.m": "Любой",
    "forms.any.f": "Любая",
    "forms.showAll": "Показать все",
    "forms.select": "Выбрать",
    "forms.selected": "Выбран",
    "forms.selectType": "Выберите тип",
    "forms.color": "Цвет",
    "forms.no": "Нет",
    "forms.solutionsSort": "Сортировать по",
    "forms.solutionsSort.time": "по дате размещения",
    "forms.solutionsSort.rating": "по оценке ИДУ",
    "forms.solutionsSort.similarity": "по сходству",
    "forms.expertsSort.comments": "по комментариям",
    "forms.save_changes": "Сохранить изменения",
    "forms.reset": "Сбросить",
    "forms.show": "Показать",
    "forms.add": "Добавить",
    "forms.save": "Сохранить",
    "forms.edit": "Редактировать",
    "forms.delete": "Удалить",
    "forms.apply": "Применить",
    "forms.clear": "Очистить",
    "forms.search.ph": "Введите поисковой запрос",
    "forms.find": "Найти",
    "forms.list": "Списком",
    "forms.map": "На карте",
    "forms.send": "Отправить",
    "forms.cancel": "Отмена",
    "download": "Скачать",
    "expand": "Развернуть",
    "collapse": "Свернуть",
    "showMore": "Показать ещё",
    "showAll": "Смотреть все",
    "similarSolutions": "Похожие проекты",
    "similarTo": "Похожие на",

    "nav.experts": "Эксперты",
    "nav.about": "О проекте",
    "nav.admin": "Админка",
    "toMainPage": "На главную",
    "footer.institute": "© 2021 Институт дизайна и урбанистики Университета ИТМО",

    "experts.registered": "Зарегистрировано",
    "experts.how.header": "Кто входит в состав экспертов сервиса?",
    "experts.how.text": "Оценку решений производят специалисты в области урбанистики, городского планирования, управления, " +
        "экономики и умного города. Вы можете отправить заявку на включение в состав экспертов на электронную почту mityagin@itmo.ru, приложив мотивационное письмо и резюме.",

    "stats.totalSolutions": "Успешных решений | Успешное решение | Успешных решения | Успешных решений",
    "stats.bestPractices": "Лучших практик | Лучшая практика | Лучшие практики | Лучших практик",
    "stats.comments": "Экспертных оценок | Экспертная оценка | Экспертные оценки | Экспертных оценок",
    "stats.updated": "Последнее обновление",

    "user.edit": "Редактировать пользователя",
    "user.change_password": "Изменить пароль",
    "user.ban": "Забанить",
    "user.unban": "Разбанить",
    "user.activate": "Активировать",
    "user.not_activated": "Пользователь не активирован",
    "user.not_logged_in": "Сначала войдите",
    "user.name.placeholder": "Введите своё имя",
    "user.password.placeholder": "Enter new password",
    "user.repeat_password.placeholder": "Repeat new password",
    "user.change_password.caption": "Change Password",
    "user.profile.caption": "Profile Settings",
    "user.addAvatar": "Добавить аватар",
    "user.changeAvatar": "Изменить аватар",

    "role.edit": "Редактировать роль",
    "role.name": "Имя роли",

    "solution.fullName": "Название полное",
    "solution.fullName.description": "Строка официального названия, данного разработчиком",
    "solution.shortName": "Название короткое",
    "solution.shortName.description": "Краткое название, под которые узнается решение",
    "solution.ideaAuthor": "Автор идеи",

    "solution.solutionType": "Тип решения",
    "solution.solutionType.BUSINESS_SOLUTION" : "Бизнес-решение",
    "solution.solutionType.BEST_PRACTICE" : "Лучшая практика",
    "solution.solutionType.PROJECT" : "Проект",
    "solution.solutionType.IDEA" : "Идея",

    "solution.implementations": "Внедрения",
    "solution.implementations.description": "Сведения о местах реализации и ответственных за реализацию. Это могут быть МО или районы или города",

    "solution.sphere": "Сфера",
    "solution.sphereType": "Сфера внедрения",
    "solution.sphereType.description": "Глобальное позиционирование решения",

    "solution.sphere.CITY_MANAGEMENT" : "Управление городом",
    "solution.sphere.SERVICES_PROVISION" : "Обеспечение горожан городскими сервисами",
    "solution.sphere.TERRITORIAL_GROWTH" : "Территориальное развитие города",

    "solution.cityManagementPurview": "Область",
    "solution.cityManagementPurview.REGULATION": "Нормативное регулирование",
    "solution.cityManagementPurview.ACTIVITY": "Исполнительная деятельность",
    "solution.cityManagementPurview.TERRITORY_MANAGEMENT": "Управление территорией",
    "solution.cityManagementPurview.LOCAL_GOVERNMENT": "Местное управление",

    "solution.industry": "Отрасль",
    "solution.industry.description": "По перечню отраслей городского хозяйства",

    "solution.infrastructure": "Инфраструктура",
    "solution.infrastructures": "Инфраструктуры платформы городских данных",
    "solution.infrastructure.BASE": "Базовая инфраструктура",
    "solution.infrastructure.SOCIAL": "Социальная инфраструктура",
    "solution.infrastructure.TRANSPORT": "Транспортная инфраструктура",
    "solution.infrastructure.SERVICE": "Инфраструктура обслуживания населения",
    "solution.infrastructure.LEISURE": "Инфраструктура досуга",
    "solution.infrastructure.TOURIST": "Туристическая инфраструктура",

    "solution.function": "Функция",
    "solution.functions": "Функции",
    "solution.serviceTypes": "Типы сервиса",
    "solution.serviceTypes.description": "Перечень типов сервисов",

    "solution.projectType": "Вид проекта",
    "solution.territorialGrowth.LOCAL_TERRITORY_COMPLEX_DEVELOPMENT": "Комплексное развитие локальной территории",
    "solution.territorialGrowth.URBAN_TRANSFORMATION": "Преобразование городской среды",
    "solution.territorialGrowth.IMPROVEMENT_OF_PUBLIC_SERVICES": "Благоустройство",

    "solution.image": "Аватарка решения",

    "solution.description" : "Описание решения",
    "solution.description.targets": "Цели решения",
    "solution.description.achievements": "Заявленные достижения",
    "solution.description.achievements.description": "Тоже результаты, которые были достигнуты. Если это идея, то тут указываются ожидаемые результаты",
    "solution.description.text": "Описание",
    "solution.description.text.description": "Текст с содержательным описанием",
    "solution.description.scale": "Масштаб решения",
    "solution.description.images": "Иллюстрирующие материалы",

    "solution.description.scale.ON_SITE": "Локальный (двор, микрорайон)",
    "solution.description.scale.LOCAL": "Местный (МО)",
    "solution.description.scale.DISTRICT": "Районный (район)",
    "solution.description.scale.CITY": "Городской",
    "solution.description.scale.REGION": "Региональный",
    "solution.description.scale.FEDERAL": "Федеральный",
    "solution.description.scale.WORLDWIDE": "Мировой",

    "solution.description.locationDescription": "Расположение",
    "solution.description.locationDescriptionPart": "Местоположение",
    "solution.description.locationDescriptionPart.placeholder": "Введите местоположение",
    "solution.description.location": "Место",
    "solution.description.location.help": "Кликните на карту или введите координаты вручную и нажмите кнопку",
    "solution.description.geometry": "Геометрические параметры",
    "solution.description.geometry.description": "Если известно, то могут быть указаны координаты и геометрия решения для изображения на карте",
    "solution.description.geometry.help": "Чтобы изменить форму многоугольника, сначала выберите нужный, затем кликните левой кнопкой мыши по карте чтобы добавить точку, или кликните левой кнопкой мыши с нажатым Ctrl, чтобы удалить последнюю точку многоугольника",
    "solution.description.files": "Файлы",

    "polygon.delete": "Удалить многоугольник",

    "solution.expertOpinion": "Экспертное заключение",
    "solution.expertOpinion.rating": "Оценка ИДУ",
    "solution.expertOpinion.rating.description": "Общая оценка решения в баллах от 1 до 5",
    "solution.expertOpinion.text": "Текст заключения",
    "solution.expertOpinion.text.description": "Текст, где мы пишем свое мнение об этом",
    "solution.expertOpinion.comments": "Комментарии экспертов",
    "solution.expertOpinion.comments.add": "Добавить свой комментарий",
    comments: "0 комментариев | {n} комментарий | {n} комментария | {n} комментариев",
    experts: "0 экспертов | {n} эксперт | {n} эксперта | {n} экспертов",

    "solution.segment": "Сегмент рынка",
    "solution.segment.PEOPLE": "Горожане",
    "solution.segment.BUSINESS": "Бизнес",
    "solution.segment.GOVERNMENT": "Органы власти",

    "solution.targetGroup": "Целевая группа",
    "solution.situation": "Жизненная ситуация",
    "solution.indicators": "Целевые показатели",
    "solution.targetValues": "Ценностные характеристики",
    "solution.focusTarget": "Целевая фокусировка",
    "solution.risks": "Риски",
    "solution.focusTargetRisk.comment": "Комментарии",

    "solution.characteristic.target": "Целевая характеристика",
    "solution.characteristic.risk": "Рискованная характеристика",
    "solution.characteristic.PROVISION": "Обеспеченность социальной группы",
    "solution.characteristic.QUALITY": "Качество сервиса",
    "solution.characteristic.VARIETY": "Многообразие сервиса",
    "solution.characteristic.AVAILABILITY": "Доступность сервиса",

    "realization": "Реализация",
    "realizations": "Реализации",
    "realization.producer": "Производитель",
    "realization.producer.description": "Название, ссылка на сайт, может быть контактные данные",
    "realization.cost": "Стоимость",
    "realization.cost.description": "Если известно",

    "realization.sourceLink": "Источник информации",
    "realization.sourceLink.description": "Ссылка на статью где это описано",
    "realization.sourceLink.BUSINESS_SOLUTION": "Источник информации",
    "realization.sourceLink.BEST_PRACTICE": "Источник информации",
    "realization.sourceLink.PROJECT": "Ссылка на проект",
    "realization.sourceLink.PROJECT.description": "Ссылка на сайт проекта или на информацию в официальных источниках. Хотя бы на википедию",
    "realization.sourceLink.IDEA": "Источник идеи",
    "realization.sourceLink.IDEA.description": "Это ссылка на источник. Это может быть статья или диплом нашего студента",
    "siteLink": "Ссылка на сайт",

    "realization.practiceAffiliation": "Принадлежность к практике",
    "realization.practiceAffiliation.description": "Внутренняя ссылка на решение типа «лучшая практика», к которому относится это решение",

    "solution.toast.saved": "Решение успешно сохранено",
    "solution.toast.error": "Ошибка при отправке решения",
    "solution.toast.error.fullName": "Решение с таким полным названием уже существует",
    "solution.toast.error.shortName": "Решение с таким коротким названием уже существует",

    "toast.success.title": "Успех",
    "toast.error.title": "Ошибка",

    "errors.not_found": "Страница не найдена",
    "errors.not_found.text": "It looks like you found a glitch in the matrix...",

    "errors.forbidden": "Forbidden",
    "errors.forbidden.text": "It looks like you do not have access to this content"
}