import Axios from "../axios"
import toastMixin from "./toast";

const SolutionAPI = {
    methods: {
        async saveSolution(solution) {
            let response = await Axios.post(process.env.VUE_APP_API_URL + `/api/v1/solution/save`, solution);
            if (response.data.code === 0) {
                this.successToast(this.$t('solution.toast.saved'));
                return response.data.data;
            }
            this.errorToast(this.$t('solution.toast.error') + `: ` +
                (response.data.message.includes('fullName') ? this.$t('solution.toast.error.fullName') : this.$t('solution.toast.error.shortName'))
            );
            return {}
        },
        async getSolution(solutionId) {
            return await Axios.get(process.env.VUE_APP_API_URL + `/api/v1/solution/${solutionId}`);
        },
        async getStats() {
            return await Axios.get(process.env.VUE_APP_API_URL + `/api/v1/solution/stats`);
        },
        async deleteSolution(solutionId) {
            return await Axios.delete(process.env.VUE_APP_API_URL + `/api/v1/solution/delete/${solutionId}`);
        },
        async listByTime(solutionType, sphereType, pageNumber, pageSize) {
            let request = process.env.VUE_APP_API_URL + `/api/v1/solution/listByTime?`;
            if (solutionType != null) {
                request += `solutionType=${solutionType}&`;
            }
            if (sphereType != null) {
                request += `sphereType=${sphereType}&`;
            }
            request += `page=${pageNumber}&size=${pageSize}`;
            return await Axios.get(request);
        },
        async listByRating(solutionType, sphereType, pageNumber, pageSize) {
            let request = process.env.VUE_APP_API_URL + `/api/v1/solution/listByRating?`;
            if (solutionType != null) {
                request += `solutionType=${solutionType}&`;
            }
            if (sphereType != null) {
                request += `sphereType=${sphereType}&`;
            }
            request += `page=${pageNumber}&size=${pageSize}`;
            return await Axios.get(request);
        },
        async count(request) {
            return await Axios.post(process.env.VUE_APP_API_URL + `/api/v1/solution/count`, request);
        },
        async find(request) {
            return await Axios.post(process.env.VUE_APP_API_URL + `/api/v1/solution/find`, request);
        },
        async commentSolution(solutionId, request) {
            return await Axios.post(process.env.VUE_APP_API_URL + `/api/v1/solution/comment/${solutionId}`, request);
        },
        async editSolutionComment(commentId, request) {
            return await Axios.post(process.env.VUE_APP_API_URL + `/api/v1/solution/editComment/${commentId}`, request);
        },
        async deleteSolutionComment(commentId) {
            return await Axios.post(process.env.VUE_APP_API_URL + `/api/v1/solution/deleteComment/${commentId}`);
        },
        async fetchFTR(solutionId) {
            return await Axios.get(process.env.VUE_APP_API_URL + `/api/v1/solution/${solutionId}/ftr`);
        },
        async fetchComments(solutionId, pageNumber, pageSize) {
            return await Axios.get(process.env.VUE_APP_API_URL + `/api/v1/solution/${solutionId}/comments?page=${pageNumber}&size=${pageSize}`);
        },
        async countComments(solutionId) {
            return await Axios.get(process.env.VUE_APP_API_URL + `/api/v1/solution/${solutionId}/countComments`);
        },
        async userCommented(solutionId) {
            return await Axios.get(process.env.VUE_APP_API_URL + `/api/v1/solution/${solutionId}/userCommented`);
        },
        async findLocationDescription(lat, lon) {
            return await Axios.get(process.env.VUE_APP_API_URL + `/api/v1/solution/findLocationDescription?lat=${lat}&lon=${lon}`);
        },
        async findAddress(address) {
            return await Axios.get(process.env.VUE_APP_API_URL + `/api/v1/solution/findAddress?address=${encodeURI(address)}`);
        },
        async getAutocompleteValues(field) {
            return await Axios.get(process.env.VUE_APP_API_URL + `/api/v1/solution/getAutocompleteValues?field=${field}`);
        },
        async getFunctionStructure() {
            return await Axios.get(process.env.VUE_APP_API_URL + `/api/v1/solution/getFunctionStructure`);
        }
    },
    mixins: [toastMixin]
};

export default SolutionAPI