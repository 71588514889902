<template>
    <b-container>
        <NavBar></NavBar>
        <b-row class="m-0 p-0 mt-5 mb-3">
            <b-col class="pl-0">
                <h1>{{ $t('experts.registered') }} {{ $tc('experts', expertsTotal) }}</h1>
            </b-col>
            <b-col class="m-0 p-0" lg="4" md="6">
                <b-dropdown class="float-right" no-caret variant="light" style="height: 36px; min-width: 270px" toggle-class="dd" @change="loadSolutions()">
                    <template #button-content>
                        <b-img :src="require('../../assets/icons/bars.svg')" class="float-left mt-1"></b-img>
                        {{ sort == 'TIMESTAMP' ? $t("forms.solutionsSort.time") : $t("forms.expertsSort.comments") }}
                        <i class="fas fa-chevron-down fa-xs float-right mt-2 ml-1"></i>
                    </template>
                    <b-dropdown-item href="#" @click="sort = 'TIMESTAMP'; currentPage = 0; experts = []; loadExperts()">
                        {{ $t("forms.solutionsSort.time") }}
                    </b-dropdown-item>
                    <b-dropdown-item href="#" @click="sort = 'COMMENTS'; currentPage = 0; experts = []; loadExperts()">
                        {{ $t("forms.expertsSort.comments") }}
                    </b-dropdown-item>
                </b-dropdown>
            </b-col>
        </b-row>
        <b-row class="m-0 p-0 mb-3">
            <b-card-group columns>
                <ExpertCard v-for="expert in experts" v-bind:key="expert.id" :expert="expert" class="mt-2 mr-1"></ExpertCard>
            </b-card-group>
        </b-row>
        <b-row class="m-0 p-0 mb-5">
            <b-button
                    v-if="expertsTotal > currentPage * perPage"
                    variant="primary"
                    class="ml-auto mr-auto"
                    @click="loadExperts"
            >
                {{ $t('showMore') }}
            </b-button>
        </b-row>
        <b-row class="m-0 p-0 mb-5">
            <b-card>
                <h1>{{ $t('experts.how.header') }}</h1>
                <p>{{ $t('experts.how.text') }}</p>
            </b-card>
        </b-row>
    </b-container>
</template>
<script>
    import UserAPI from "../../mixins/UserAPI";
    import NavBar from "../../components/NavBar";
    import ExpertCard from "../../components/ExpertCard";

    export default {
        name: "ExpertsPage",
        components: { NavBar, ExpertCard },
        mixins: [ UserAPI ],
        data() {
            return {
                expertsTotal: 0,
                currentPage: 0,
                perPage: 12,
                experts: [],
                sort: 'TIMESTAMP'
            }
        },
        methods: {
            async loadExperts() {
                this.experts = this.experts.concat(await this.listExperts(this.currentPage, this.perPage, this.sort));
                this.currentPage++;
            },
            async countExpertsL() {
                this.expertsTotal = await this.countExperts();
            }
        },
        mounted() {
            this.loadExperts();
            this.countExpertsL();
        }
    }
</script>
<style scoped>
    .btn, .btn-group, .b-dropdown, .input-group, .custom-select, .form-control {
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
        border: none;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
    }

    >>> .dropdown-toggle {
        color: #808080;
    }

    .btn-primary {
        padding: 10px 40px 10px 40px;
        background: #074A74;
    }

    h1 {
        margin-bottom: 24px;
        font-size: 20px;
        line-height: 23px;
        color: #000;
    }

    p {
        font-size: 14px;
        line-height: 16px;
        color: #000;
    }

    .card {
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        border: none;
    }

    .card-columns {
        column-gap: 20px;
    }

    .card-columns .card {
        margin-bottom: 20px;
    }

    .card-body {
        padding: 24px 32px 24px 32px;
    }
</style>