<template style="height: 100vh">
    <div id="app" class="d-flex flex-column" style="height:100vh;">
        <div class="flex-grow-1" style="background-color: #F4F4F5">
            <router-view></router-view>
        </div>
        <b-navbar toggleable="lg" type="dark" variant="primary" class="p-0 n-footer">
            <b-container class="p-0">
                <b-navbar-brand>
                    <b-link href="https://actcognitive.org/">
                        <img src="~@/assets/logo.png" height="35" width="119" style="margin: 15px 0px 20px 0px">
                    </b-link>
                </b-navbar-brand>
                <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav class="ml-auto">
                        <b-nav-text>
                            <b-link href="https://idu.itmo.ru/ru/">
                                {{ $t('footer.institute') }}
                            </b-link>
                        </b-nav-text>
                    </b-navbar-nav>
                </b-collapse>
            </b-container>
        </b-navbar>
    </div>
</template>

<script>
    export default {
        name: 'App',
        components: {
        }
    }
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

    #app {
        padding-top: 68px;
        font-family: 'Roboto', sans-serif;
    }
    .n-footer {
        background-color: #074A74 !important;
    }
    .n-footer > .navbar-brand {
        padding: 0;
    }
    .n-footer > .navbar-collapse > .navbar-nav > .navbar-text {
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        padding: 0;
        margin: 34px 150px 16px 0px;
    }
</style>
<style>
    .btn-primary {
        background: #074A74 !important;
        border: none !important;
    }
    .btn-outline-primary {
        color: #074A74 !important;
        border: none !important;
    }
    .btn-primary:hover, .btn-primary:focus {
        background: #0087CC !important;
    }
    .btn-outline-primary:hover {
        background: #e0e0e0 !important;
    }
    .vbt-autcomplete-list {
        z-index: 1001 !important;
    }
    .custom-select {
        background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='4' %3e%3cpath fill='%23808080' d='M0 1 L4 4 L8 1 L7 0 L4 2 L1 0 Z'/%3e%3c/svg%3e") no-repeat right 0.75rem top 50% !important;
    }
</style>