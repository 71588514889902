<template>
    <div style="position: relative">
        <div class="stars-container">
            <div class="fill-bg"></div>
            <div class="fill-r" :style="'width: ' + (rating * 26) +'px'"></div>
            <div class="stars">
                <svg width="130" height="24" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                    <path :d="starHoles()"></path>
                </svg>
            </div>
        </div>
        <div class="rating-label">{{ rating.toFixed(1) }} / 5.0</div>
    </div>
</template>
<script>
    export default {
        name: "Rating",
        props: ["rating"],
        methods: {
            starHoles() {
                let d = 'M0 0 h131 v25 h-131Z'; //white rectangle around star holes (cw)
                for (var i = 0; i < 5; i++) { //stars (ccw)
                    let step = i * 26;
                    d += ' M' + step + ' 8.4L' + (6.5 + step) + ' 13.8L' + (4.6 + step) + ' 21.9L' + (11.9 + step) +
                    ' 17.6L' + (19.3 + step) + ' 21.9L' + (17.4 + step) + ' 13.8L' + (24 + step) + ' 8.3L' + (15.3 + step) +
                    ' 7.6L' + (11.9 + step) + ' 0L' + (8.6 + step) + ' 7.6Z';
                }
                return d;
            }
        }
    }
</script>
<style scoped>
    .rating-label {
        color: #000;
        float: right;
        font-size: 20px;
        line-height: 23px;
    }
    .b-rating.disabled {
        background-color: #fff;
        border: none;
        padding: 0px;
    }
    >>>.b-rating-star {
        padding: 0px 1px 0px 1px;
    }
    .stars-container {
        display: flex;
        position: static;
        left: 1px;
        top: 8px;
        width: 130px;
        height: 24px;
        margin-bottom: 10px;
    }
    .fill-bg {
        z-index: 0;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgb(244, 244, 245);
        width: 130px;
        height: 24px;
    }
    .fill-r {
        z-index: 1;
        position: absolute;
        left: 0;
        top: 0;
        background-color: #FF951A;
        height: 24px;
    }
    .stars {
        z-index: 2;
        position: absolute;
        left: 0;
        top: 0;
        line-height: 0px;
    }
</style>